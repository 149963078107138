import * as React from 'react';
import styled from 'styled-components/native';
import PrimaryButton from '../ui/buttons/primary/primary-button';
import Title from '../ui/type/title/title';
import { ParticleMarkIcon } from '../ui/icons/particle-mark-icon';
import { useAuth } from '../../hooks';
import { ClientBrandType } from '../../types/user-auth';

interface AuthCheckProps {
	children: React.ReactNode;
	branding?: ClientBrandType;
}

export default function AuthCheck({ children, branding }: AuthCheckProps): JSX.Element {
	const { token, requestAuth, loading } = useAuth();

	if (!token) {
		return (
			<Container>
				<Content>
					{loading || !branding ? (
						<Title size='medium'>Loading...</Title>
					) : (
						<React.Fragment>
							<ParticleMarkIcon size='250' color={branding.logoColor} />
							<PrimaryButton
								size='medium'
								title={branding.orgName || 'Sign-in'}
								onPress={requestAuth}
							/>
						</React.Fragment>
					)}
				</Content>
			</Container>
		);
	}

	if (typeof children === 'function') {
		return children();
	}

	return <React.Fragment>{children}</React.Fragment>;
}

export const Container = styled.View`
	display: flex;
	flex-direction: column;
	min-height: 100%;
`;

export const Content = styled.View`
	min-height: 100%;
	align-items: center;
	justify-content: center;
	background-color: ${(props) => props.theme.components.screen.background};
`;
