import React from 'react';
import * as Styled from './styled';
import { Paragraph, Title } from '../type';
import { PrimaryButton } from '../buttons';
import { EmptyMessageProps } from './types';
import { Spacer } from '../spacer';

export const EmptyMessage: React.FC<EmptyMessageProps> = ({
	title = "We're sorry, we couldn't find any results",
	body = 'We recommend starting with fewer filters or keywords',
	cta,
	height
}) => (
	<Styled.Container style={{ height }}>
		<Title size='small'>{title}</Title>
		<Spacer direction='vertical' size='extraSmall' />
		<Paragraph size='regular'>{body}</Paragraph>
		<Spacer direction='vertical' size='medium' />
		{cta ? (
			<PrimaryButton size='medium' title={cta.label} onPress={cta.onPress} />
		) : (
			<Spacer direction='vertical' size='large' />
		)}
	</Styled.Container>
);
