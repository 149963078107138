import add from 'date-fns/add';
import startOfMinute from 'date-fns/startOfMinute';
import sub from 'date-fns/sub';
import formatDate from 'date-fns/format';
import { Domain } from './types';
import {
	FLYOUT_CURSOR_MARGIN_X,
	FLYOUT_CURSOR_MARGIN_Y,
	FLYOUT_HEIGHT,
	FLYOUT_WIDTH
} from './constants';
import {
	compareAsc,
	differenceInDays,
	differenceInHours,
	differenceInMonths,
	differenceInWeeks
} from 'date-fns';

export const getLast12HoursDomain = (): Domain => {
	const lastDate = startOfMinute(new Date(Date.now()));
	return { start: sub(lastDate, { hours: 12 }), end: lastDate };
};
export const getLastDayDomain = (): Domain => {
	const lastDate = startOfMinute(new Date(Date.now()));
	return { start: sub(lastDate, { days: 1 }), end: lastDate };
};
export const getLastWeekDomain = (): Domain => {
	const lastDate = startOfMinute(new Date(Date.now()));
	return { start: sub(lastDate, { weeks: 1 }), end: lastDate };
};
export const getLastMonthDomain = (): Domain => {
	const lastDate = startOfMinute(new Date(Date.now()));
	return { start: sub(lastDate, { months: 1 }), end: lastDate };
};

export const formatTick = (tick: number): string => {
	const date = new Date(tick);

	return `${formatDate(date, 'MMM d')}\n${formatDate(date, 'HH:mm')}`;
};

export const getFlyoutSafeAreaY = (y: number, chartHeight: number): number => {
	if (y + FLYOUT_HEIGHT - FLYOUT_CURSOR_MARGIN_Y > chartHeight) {
		return Math.max(y - FLYOUT_HEIGHT + FLYOUT_CURSOR_MARGIN_Y, 0);
	}
	return y - FLYOUT_CURSOR_MARGIN_Y;
};
export const getFlyoutSafeAreaX = (x: number, chartWidth: number): number => {
	if (x + FLYOUT_WIDTH + FLYOUT_CURSOR_MARGIN_X > chartWidth) {
		return Math.max(x - FLYOUT_WIDTH - FLYOUT_CURSOR_MARGIN_X, 0);
	}
	return x + FLYOUT_CURSOR_MARGIN_X;
};

const getDomainDiff = (domain: Domain) => {
	return {
		months: differenceInMonths(domain.end, domain.start),
		weeks: differenceInWeeks(domain.end, domain.start),
		days: differenceInDays(domain.end, domain.start),
		hours: differenceInHours(domain.end, domain.start)
	};
};

const isGreaterOrEqualTo = (date: Date, to: Date) => {
	return compareAsc(date, to) >= 0;
};

const isValidDomain = (domain: Domain) => {
	const maxDate = new Date(Date.now());
	const minDate = sub(maxDate, { months: 1 });

	return (
		isGreaterOrEqualTo(domain.start, minDate) &&
		isGreaterOrEqualTo(maxDate, domain.end)
	);
};

const getPrevAndNextDomains = (
	domain: Domain,
	diff:
		| { months: number }
		| { weeks: number }
		| { days: number }
		| { hours: number }
) => {
	const prev = {
		start: sub(domain.start, diff),
		end: domain.start
	};
	const next = {
		start: domain.end,
		end: add(domain.end, diff)
	};
	return {
		prev: isValidDomain(prev) ? prev : null,
		next: isValidDomain(next) ? next : null
	};
};

export const getDomainPagination = (domain: Domain) => {
	const diff = getDomainDiff(domain);
	if (diff.months > 0) {
		return getPrevAndNextDomains(domain, { months: diff.months });
	}
	if (diff.weeks > 0) {
		return getPrevAndNextDomains(domain, { weeks: diff.weeks });
	}
	if (diff.days > 0) {
		return getPrevAndNextDomains(domain, { days: diff.days });
	}
	return getPrevAndNextDomains(domain, { hours: diff.hours });
};
