import styled from 'styled-components/native';
import { css } from 'styled-components/native';
import { FullScreenMode } from './types';

export const OverlayTouchable = styled.TouchableWithoutFeedback``;

export const Overlay = styled.View`
	display: flex;
	background-color: ${({ theme }) => theme.components.modal.overlay};
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
`;

const getSizeAndPositionStyle = ({
	isFullScreen,
	minWidth,
	minHeight
}: FullScreenMode) => {
	if (isFullScreen) {
		return css({
			width: '100%',
			height: '100%',
			margin: '0',
			borderRadius: '0'
		});
	}
	return css({
		minWidth,
		minHeight,
		maxHeight: '90%',
		margin: 'auto',
		borderRadius: '8px'
	});
};

export const Container = styled.SafeAreaView<FullScreenMode>`
	position: relative;
	background-color: ${({ theme }) => theme.components.modal.background};
	${(props) => getSizeAndPositionStyle(props)}
`;

export const Header = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 24px;
`;

export const Body = styled.ScrollView`
	display: flex;
	padding: 0 24px;
`;

export const Footer = styled.View`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 24px;
	border-top-width: 1px;
	border-color: ${({ theme }) => theme.components.modal.footer.border};
`;
