import React from 'react';
import { useMultiSelectState } from '../../../hooks';
import { LinkButton } from '../buttons';
import { Checkbox } from '../checkbox/checkbox';
import { CheckboxValue } from '../checkbox/types';
import { Label } from '../type/label/styled';
import * as Styled from './styled';

interface Option {
	value: string;
	label: string;
}
interface CheckboxGroupProps {
	title: string;
	maxOptions?: number;
	options: Option[];
	initiallyChecked?: Set<CheckboxValue>;
	value?: Set<CheckboxValue>;
	showMoreLabel?: string;
	onShowMore?: () => void;
	onChange: (checked: Set<CheckboxValue>) => void;
}

export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
	title,
	options,
	showMoreLabel = 'Show more',
	initiallyChecked,
	value,
	onShowMore,
	onChange,
	maxOptions = 4
}) => {
	const { toggle, isSelected, count } = useMultiSelectState({
		value,
		onSelect: onChange,
		initialState: initiallyChecked
	});

	return (
		<Styled.Container>
			<Styled.Title>
				<Label type='bold'>{title}</Label>
				<Styled.SelectedLabel type='bold'>
					{' '}
					({count} selected)
				</Styled.SelectedLabel>
			</Styled.Title>
			{options.slice(0, maxOptions).map((option) => (
				<Styled.OptionWrapper key={option.value}>
					<Checkbox
						value={option.value}
						label={option.label}
						checked={isSelected(option.value)}
						onPress={({ value }) => toggle(value)}
					/>
				</Styled.OptionWrapper>
			))}
			{options.length > maxOptions && (
				<LinkButton size='small' title={showMoreLabel} onPress={onShowMore} />
			)}
		</Styled.Container>
	);
};
