import React, { useState } from 'react';
import styled from 'styled-components/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useTheme } from 'styled-components/native';
import {
	Checkbox,
	CheckboxGroup,
	LinkButton,
	Modal,
	ScreenHeader,
	SelectableTag,
	Tag,
	TagsGroup
} from '../../components';
import { View } from 'react-native';
import Title from '../../components/ui/type/title/title';
import Paragraph from '../../components/ui/type/paragraph/paragraph';
import Label from '../../components/ui/type/label/label';
import PrimaryButton from '../../components/ui/buttons/primary/primary-button';
import MonitorIcon from '../../components/ui/icons/monitor';
import AddIcon from '../../components/ui/icons/add';
import DeleteIcon from '../../components/ui/icons/delete';
import DevicesIcon from '../../components/ui/icons/devices';
import DocsIcon from '../../components/ui/icons/docs';
import DownArrowIcon from '../../components/ui/icons/down-arrow';
import DownloadIcon from '../../components/ui/icons/download';
import EditIcon from '../../components/ui/icons/edit';
import ExportIcon from '../../components/ui/icons/export';
import FilterIcon from '../../components/ui/icons/filter';
import ImportIcon from '../../components/ui/icons/import';
import IntegrationsIcon from '../../components/ui/icons/integrations';
import LocationIcon from '../../components/ui/icons/location';
import LogoutIcon from '../../components/ui/icons/logout';
import MainNavigationIcon from '../../components/ui/icons/main-navigation';
import MoreIcon from '../../components/ui/icons/more';
import NotificationsIcon from '../../components/ui/icons/notifications';
import ProfileIcon from '../../components/ui/icons/profile';
import SearchIcon from '../../components/ui/icons/search';
import SettingsIcon from '../../components/ui/icons/settings';
import SupportIcon from '../../components/ui/icons/support';
import UpArrowIcon from '../../components/ui/icons/up-arrow';
import VitalsIcon from '../../components/ui/icons/vitals';

const Stack = createStackNavigator();

const Container = styled.ScrollView`
	background-color: ${({ theme }) => theme.components.screen.background};
	min-height: 100%;
	padding: 10px;
`;

const Divider: React.FC = () => {
	return (
		<View
			style={{
				borderBottomColor: 'white',
				borderBottomWidth: 1,
				marginTop: 10
			}}
		/>
	);
};

const TypesContainer = () => (
	<View>
		<Title size='medium'>Text:</Title>
		<Title size='large'>Large Title</Title>
		<Title size='medium'>Medium Title</Title>
		<Title size='small'>Small Title</Title>
		<Paragraph size='regular'>Regular Paragraph</Paragraph>
		<Paragraph size='small'>Small Paragraph</Paragraph>
		<Label type='regular'>Regular Label</Label>
		<Label type='bold'>Bold Label</Label>
		<Divider />
	</View>
);

const ColorsContainer = () => {
	const { colors } = useTheme();

	return (
		<View>
			<Title size='medium'>Colors:</Title>
			<View style={{ flexDirection: 'row' }}>
				{Object.entries(colors).map((el, index) => {
					return (
						<View key={index}>
							<Label type='regular'>{el[0]}</Label>
							<View
								style={{
									height: 50,
									width: 50,
									margin: 5,
									backgroundColor: el[1],
									borderRadius: 10
								}}
							></View>
						</View>
					);
				})}
			</View>
			<Divider />
		</View>
	);
};

const IconsContainer = () => (
	<View>
		<Title size='medium'>Icons:</Title>
		<View>
			<View style={{ flexDirection: 'row' }}>
				<MonitorIcon />
				<AddIcon />
				<DeleteIcon />
				<DevicesIcon />
				<DocsIcon />
				<DownArrowIcon />
				<EditIcon />
				<DownloadIcon />
			</View>
			<View style={{ flexDirection: 'row' }}>
				<ExportIcon />
				<FilterIcon />
				<ImportIcon />
				<IntegrationsIcon />
				<LocationIcon />
				<LogoutIcon />
				<MoreIcon />
				<MainNavigationIcon />
			</View>
			<View style={{ flexDirection: 'row' }}>
				<NotificationsIcon />
				<ProfileIcon />
				<SearchIcon />
				<SettingsIcon />
				<SupportIcon />
				<UpArrowIcon />
				<VitalsIcon />
			</View>
		</View>
		<Divider />
	</View>
);

const ButtonsContainer = () => (
	<View>
		<Title size='medium'>Buttons:</Title>
		<PrimaryButton
			style={{ margin: '10px' }}
			size='small'
			title='Small Primary Button'
		/>
		<PrimaryButton
			style={{ margin: '10px' }}
			size='medium'
			title='Medium Primary Button'
		/>
		<PrimaryButton
			style={{ margin: '10px' }}
			size='large'
			title='Large Primary Button'
		/>
		<LinkButton
			style={{ margin: '10px' }}
			size='small'
			title='Medium Link Button'
		/>
		<LinkButton
			style={{ margin: '10px' }}
			size='medium'
			title='Medium Link Button'
		/>
		<LinkButton
			style={{ margin: '10px' }}
			size='large'
			title='Large Link Button'
		/>
		<Divider />
	</View>
);

const TagsContainer = () => {
	const tags = [
		{ value: 'one', label: 'One' },
		{ value: 'two', label: 'Two' },
		{ value: 'three', label: 'Three' },
		{ value: 'four', label: 'Four' }
	];
	return (
		<View>
			<Title size='medium'>Tags:</Title>
			<View style={{ flexDirection: 'row', marginBottom: '10px' }}>
				<Tag label='Simple tag' />

				<SelectableTag label='Selectable tag' selected onPress={console.log} />
			</View>

			<TagsGroup
				title='Uncontrolled Tags Group'
				tags={tags}
				onSelect={console.log}
			/>
			<TagsGroup
				title='Controlled Tags Group'
				tags={tags}
				value={new Set(['two', 'four'])}
				onSelect={console.log}
			/>
			<Divider />
		</View>
	);
};

const CheckboxContainer = () => {
	const options = [
		{
			value: 'checkbox1',
			label: 'checkbox1'
		},
		{
			value: 'checkbox2',
			label: 'checkbox2'
		},
		{
			value: 'checkbox3',
			label: 'checkbox3'
		},
		{
			value: 'checkbox4',
			label: 'checkbox4'
		},
		{
			value: 'checkbox5',
			label: 'checkbox5'
		}
	];

	return (
		<View>
			<Title size='medium'>Checkbox:</Title>
			<Checkbox value='check1' label='Check 1' onPress={console.log} />
			<Checkbox value='check2' checked label='Check 2' onPress={console.log} />
			<CheckboxGroup
				title='Uncontrolled group'
				options={options}
				onChange={console.log}
			/>
			<CheckboxGroup
				title='Controlled group'
				options={options}
				value={new Set(['checkbox1', 'checkbox3', 'checkbox5'])}
				onChange={console.log}
			/>
			<Divider />
		</View>
	);
};

const ModalContainer = () => {
	const [isOpen, setIsOpen] = useState(false);
	/* istanbul ignore next */
	const open = () => setIsOpen(true);
	/* istanbul ignore next */
	const close = () => setIsOpen(false);

	return (
		<View>
			<Title size='medium'>Modal:</Title>
			<PrimaryButton size='medium' title='Open' onPress={open} />

			<Modal isOpen={isOpen} onRequestClose={close}>
				<Modal.Header title='Testing' />
				<Modal.Body>
					{Array.from({ length: 100 }).map((_, index) => (
						<View
							key={index}
							style={{
								backgroundColor: index % 2 ? 'rgba(0,0,0,0.3)' : 'transparent'
							}}
						>
							<Label type={'regular'}>Row {index}</Label>
						</View>
					))}
				</Modal.Body>
				<Modal.Footer>
					<LinkButton size='medium' title='Cancel' onPress={close} />
					<PrimaryButton size='medium' title='OK' onPress={close} />
				</Modal.Footer>
			</Modal>
			<Divider />
		</View>
	);
};

const StyleGuideContainer = () => {
	return (
		<Container>
			<TypesContainer />
			<ColorsContainer />
			<IconsContainer />
			<ButtonsContainer />
			<TagsContainer />
			<CheckboxContainer />
			<ModalContainer />
		</Container>
	);
};

export const StyleGuide: React.FC = () => (
	<Stack.Navigator
		screenOptions={{
			header: () => <ScreenHeader title='Style Guide' />
		}}
	>
		<Stack.Screen name='style-guide' component={StyleGuideContainer} />
	</Stack.Navigator>
);
