import React from 'react';
import * as Styled from './styled';
import { TitleProps } from './types';


const Title: React.FC<TitleProps> = ({ children, size, style }) => {
	return (
		<Styled.Title size={size} style={style}>
			{children}
		</Styled.Title>
	);
};
Title.defaultProps = {
	size: 'large'
};

export default Title;
