/* eslint-disable camelcase*/
import React from 'react';
import * as Styled from './styled';
import { TextProps } from './types';


const Text: React.FC<TextProps> = ({ children, style }) => {
	return (
		<Styled.Type style={[style]}>{children}</Styled.Type>
	);
};

export default Text;
