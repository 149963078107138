import React from 'react';
import { ReactElement } from 'react';
import Constants from 'expo-constants';
import {
	DocumentIcon,
	SupportIcon,
	VitalsIcon,
	EditIcon
} from '../ui';
import { VitalsScreen, StyleGuide } from '../../screens';
import { Link, LinksList } from '../ui/layout/nav-bar/types';
import { openExternalLink } from './open-external-link';

interface NavRoute {
	title: string;
	icon: ({ size, color }: { size: number; color: string }) => ReactElement;
	name: string;
	component: React.FC;
}

export const NAV_ROUTES: NavRoute[] = [
	{
		title: 'Vitals',
		name: 'Vitals',
		icon: ({ size, color }) => <VitalsIcon size={`${size}px`} color={color} />,
		component: VitalsScreen
	}
];

if (Constants.manifest.extra?.environment !== 'production'){
	NAV_ROUTES.push({
		name: 'StyleGuide',
		title: 'Style Guide',
		icon: ({ size, color }) => <EditIcon size={`${size}px`} color={color} />,
		component: StyleGuide
	});
}

export const EXTRA_ROUTES: LinksList[] = [
	{
		links: [
			{
				key: 'docs',
				title: 'Docs',
				href: 'https://docs.particle.io',
				icon: () => <DocumentIcon size='36px' />
			},
			{
				key: 'support',
				title: 'Support',
				href: 'https://support.particle.io',
				icon: () => <SupportIcon size='36px' />
			}
		],
		onLinkPress: async (link: Link): Promise<void> => {
			if (!('href' in link)) {
				return;
			}

			await openExternalLink(link.href);
		}
	}
];
