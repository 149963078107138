import { gql, QueryResult, useQuery } from '@apollo/client';

export const GET_DEVICE_QUERY = gql`
	query GetFleetConnectivity(
		$orderBy: DeviceConnectivitySort
		$pagination: Pagination
		$filter: DeviceConnectivityFilter
	) {
		fleetConnectivity(
			orderBy: $orderBy
			pagination: $pagination
			filter: $filter
		) {
			connectivity {
				deviceName
				deviceId
				deviceSerialNumber
				lastHeard
				connectivityLevel
				connectivityPercent
				connectivityType
				operator
				cellAreaLocation
				groups
				radioAccessTechnology
				platform
				product
				firmwareVersion
				deviceOSVersion
				online
			}
			pageInfo {
				totalCount
			}
		}
	}
`;

type Sorting = 'asc' | 'desc';

type ConnectivityLevelValues = {
	'No Data': string;
	Poor: string;
	Fair: string;
	Good: string;
};

type ConnectivityTypeValues = {
	Cellular: string;
	'Wi-Fi': string;
};

export interface Connectivity {
	connectivityLevel: keyof ConnectivityLevelValues;
	connectivityPercent: number | null;
	cellAreaLocation: string | null;
	connectivityType: keyof ConnectivityTypeValues;
	deviceName: string | null;
	deviceId: string;
	deviceSerialNumber: string | null;
	groups: string[];
	lastHeard: string | null;
	deviceOSVersion: string | null;
	firmwareVersion: string | null;
	operator: string | null;
	product: string;
	radioAccessTechnology: string | null;
	platform: string;
	online: boolean;
}

export interface OrderBy {
	deviceName?: Sorting;
	lastHeard?: Sorting;
	connectivityLevel?: Sorting;
	connectivityType?: Sorting;
	operator?: Sorting;
	cellAreaLocation?: Sorting;
	groups?: Sorting;
	product?: Sorting;
	deviceOSVersion?: Sorting;
	firmwareVersion?: Sorting;
}

export interface Pagination {
	first: number | undefined;
	offset: number | undefined;
}

export interface Filter {
	keyword: string;
}

export interface PageInfo {
	totalCount: number;
}

export interface FleetConnectivity {
	connectivity: Connectivity[];
	pageInfo: PageInfo;
}

export interface GetDeviceData {
	fleetConnectivity?: FleetConnectivity;
}

export interface useFleetConnectivityInfoQuery {
	orderBy: OrderBy;
	pagination: Pagination;
	filter?: Filter;
}

export const useFleetConnectivityInfo = ({
	orderBy,
	pagination,
	filter
}: useFleetConnectivityInfoQuery): QueryResult<GetDeviceData> => {
	// TODO (jaime): figure how to integrate with apollos-client's pagination APIs
	// such that fetching data is decoupled from paging in the UI
	// see: https://www.apollographql.com/docs/react/pagination/core-api/
	return useQuery<GetDeviceData>(GET_DEVICE_QUERY, {
		variables: {
			orderBy,
			pagination,
			filter
		},
		fetchPolicy: 'network-only'
	});
};
