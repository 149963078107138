import React from 'react';
import { Text } from 'react-native';
import { ButtonProps } from '../types';
import * as Styled from './styled';

export const LinkButton: React.FC<ButtonProps> = ({
	size,
	title,
	onPress,
	style
}) => {
	return (
		<Text style={style}>
			<Styled.Container onPress={onPress}>
				<Styled.Content size={size}>{title}</Styled.Content>
			</Styled.Container>
		</Text>
	);
};
