import styled from 'styled-components/native';
import { Paragraph } from '../../../components';
import { Picker } from '@react-native-picker/picker';

export const Paginator = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

export const PaginatorText = styled(Paragraph)`
	align-self: center;
	padding: 10px;
`;

export const PaginatorPicker = styled(Picker)`
	background-color: #1b3856;
	width: 60px;
	padding: 3px;
	border-color: #1b3856;
	color: white;
	border-radius: 4px;
	margin-right: auto;
`;

export const PaginatorArrow = styled.Pressable`
	background-color: ${({ disabled }) => (disabled ? 'transparent' : '#1B3856')};
	display: flex
	border-radius: 4px;
	justify-content: center;
	margin-right: 5px;
`;
