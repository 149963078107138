import { gql, QueryResult, useQuery } from '@apollo/client';
import { ConnectivitySummary } from '../types/connectivity-summary';

const GET_CONNECTIVITY_SUMMARY_QUERY = gql`
	query GetConnectivitySummary($from: Timestamp, $to: Timestamp) {
		connectivitySummary(from: $from, to: $to) {
			good
			fair
			poor
			noData
			total
			timeStamp
		}
	}
`;

export interface ConnectivitySummaryResponse {
	connectivitySummary: [ConnectivitySummary];
}

export const useConnectivitySummaryQuery = (
	from: Date,
	to: Date
): QueryResult<ConnectivitySummaryResponse> =>
	useQuery<ConnectivitySummaryResponse>(GET_CONNECTIVITY_SUMMARY_QUERY, {
		variables: {
			from: from.toISOString(),
			to: to.toISOString()
		},
		fetchPolicy: 'network-only'
	});
