import styled from 'styled-components/native';
import { ButtonContainerProps, ButtonContentProps } from './types';

// TODO (jaime): Add Font to component
export const ButtonContainer = styled.TouchableHighlight<ButtonContainerProps>`
	background-color: white;
	text-align: center;
`;

export const ButtonContent = styled.Text<ButtonContentProps>`
	padding: 7px 20px;
	font-family: Inter_400Regular;
	font-size: ${({ size }) => {
		switch (size) {
			case 'small':
				return '12px';
			case 'medium':
				return '14px';
			case 'large':
				return '18px';
		}
	}};
`;
