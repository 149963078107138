import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ErrorHandler, onError } from '@apollo/client/link/error';
import Constants from 'expo-constants';
import AsyncStorage from '@react-native-async-storage/async-storage';
import fetch from 'cross-fetch';
import { persistCache, AsyncStorageWrapper } from 'apollo3-cache-persist';
import { getAccessToken, clearAccessToken, clearRefreshToken } from './auth-store';

export interface HeaderContext {
	headers:{
		authorization: string
	}
}
const httpLink = createHttpLink({
	uri: Constants.manifest.extra?.apiURL,
	credentials: 'same-origin', // include,
	fetch
});
const cache = new InMemoryCache();
let client: ApolloClient<any>; // eslint-disable-line @typescript-eslint/no-explicit-any


type AuthLinkContextParams = (firstArg: any, { headers }: any) => Promise<HeaderContext>; // eslint-disable-line @typescript-eslint/no-explicit-any


export const authLinkContext: AuthLinkContextParams = async (_, { headers }) => {
	let token: string | null = null;
	if (client) {
		token = await getAccessToken();
	}
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
		}
	};
};

export const errorHandler: ErrorHandler = ({ graphQLErrors, operation, forward }) => {
	(async () => {
		if (graphQLErrors) {
			for (const error of graphQLErrors) {
				if (error.extensions?.code === 'UNAUTHENTICATED') {
					await clearAccessToken();
					await clearRefreshToken();
				}
			}
		}
		forward(operation);
	})();
};

export const getClient = async (): Promise<ApolloClient<any>> => { // eslint-disable-line @typescript-eslint/no-explicit-any
	if (!client) {
		await persistCache({
			cache,
			storage: new AsyncStorageWrapper(AsyncStorage),
		});
		client = new ApolloClient({
			cache,
			link: setContext(authLinkContext).concat(onError(errorHandler)).concat(httpLink)
		});
	}
	return client;
};
