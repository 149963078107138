import { useState } from 'react';
import { QueryResult } from '@apollo/client';
import { useConnectivitySummaryQuery } from '../../../../hooks';
import { ConnectivitySummary } from '../../../../types/connectivity-summary';
import { ConnectivityChartState, ConnectivityInfo, Domain } from './types';
import {
	getDomainPagination,
	getLast12HoursDomain,
	getLastDayDomain,
	getLastMonthDomain,
	getLastWeekDomain
} from './utils';
import { ConnectivitySummaryResponse } from '../../../../hooks/use-connectivity-summary-query';

const asChartDataItem = (
	summaryItem: ConnectivitySummary
): ConnectivityInfo => ({
	...summaryItem,
	x: new Date(summaryItem.timeStamp).getTime()
});

const getConnectivitySummary = ({
	loading,
	data,
	previousData
}: QueryResult<ConnectivitySummaryResponse>): ConnectivitySummary[] => {
	if (loading) {
		return previousData?.connectivitySummary || [];
	}

	return data?.connectivitySummary || [];
};

export const useConnectivityChartState = (): ConnectivityChartState => {
	const height = 215;
	const [width, setWidth] = useState(0);
	const [domain, setDomain] = useState<Domain>(getLastDayDomain());
	const queryResult = useConnectivitySummaryQuery(domain.start, domain.end);
	const connectivitySummary = getConnectivitySummary(queryResult).map(
		asChartDataItem
	);
	const domainPagination = getDomainPagination(domain);
	const paginationEnabled = !queryResult.loading;
	const pagination = {
		hasNext: domainPagination.next !== null,
		hasPrev: domainPagination.prev !== null,
		onNext: () => {
			if (paginationEnabled && domainPagination.next !== null) {
				setDomain(domainPagination.next);
			}
		},
		onPrev: () => {
			if (paginationEnabled && domainPagination.prev !== null) {
				setDomain(domainPagination.prev);
			}
		}
	};

	const timeWindowButtonHandlers = {
		'12hours': () => {
			setDomain(getLast12HoursDomain());
			return true;
		},
		'1day': () => {
			setDomain(getLastDayDomain());
			return true;
		},
		'1week': () => {
			setDomain(getLastWeekDomain());
			return true;
		},
		'1month': () => {
			setDomain(getLastMonthDomain());
			return true;
		}
	};

	return {
		height,
		width,
		setWidth,
		domain,
		connectivitySummary,
		loading: queryResult.loading,
		error: queryResult.error,
		timeWindowButtonHandlers,
		pagination
	};
};
