import { useState } from 'react';
import sub from 'date-fns/sub';
import { useConnectivitySummaryQuery } from '../../hooks/use-connectivity-summary-query';
import { DeviceCounterState } from './types';


export const useDeviceCounterState = (): DeviceCounterState => {
	const [start, setStart] = useState(new Date());
	const from = sub(start, { hours: 1 });
	const result = useConnectivitySummaryQuery(from, start);
	const data = result.data?.connectivitySummary
		? result.data.connectivitySummary[result.data.connectivitySummary.length - 1]
		: undefined;

	return {
		start,
		setStart,
		data,
		loading: result.loading
	};
};

