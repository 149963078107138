import React from 'react';
import * as Styled from './styled';
import Title from '../../type/title/title';
import { ProfileDropdown } from './profile-dropdown';
import { PrimaryButton } from '../../buttons';
import { useAuth } from '../../../../hooks';

// TODO(carlos): Remove this component once the auth flow is fully functional
const ProfileDropdownWrapper = () => {
	const { requestAuth, user } = useAuth();

	if (!user) {
		return <PrimaryButton title='Login' size='medium' onPress={requestAuth} />;
	}
	return <ProfileDropdown />;
};

interface ScreenHeaderProps {
	title?: string;
}

export const ScreenHeader: React.FC<ScreenHeaderProps> = ({ title }) => {
	return (
		<Styled.Container>
			<Styled.Leading>
				<Title size='large'>{title}</Title>
			</Styled.Leading>
			<Styled.Trailing>
				{/*
					TODO (mirande): see git for original <NotificationButton />
					implementation. run: git checkout ee1b70203b15f7b3c6bbb647c8ece1fbb6c670d1
				*/}
				<ProfileDropdownWrapper />
			</Styled.Trailing>
		</Styled.Container>
	);
};
