import styled from 'styled-components/native';
import { ButtonContainer, ButtonContent } from '../button';

export const Container = styled(ButtonContainer)`
	background-color: ${({ theme }) => theme.components.buttons.link.background};
	border-radius: 8px;
`;

export const Content = styled(ButtonContent)`
	padding: 5px 0;
	color: ${({ theme }) => theme.components.buttons.link.color};
`;
