import styled from 'styled-components/native';
import { TagWrapperProps } from './types';

export const Container = styled.View``;

export const TagWrapper = styled.Text<TagWrapperProps>`
	margin-right: ${(props) => (props.last ? '0' : '8px')};
`;

export const Tags = styled.View`
	flex-direction: row;
`;
