import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';


// TODO (jaime): Re export some icons to match our default icon logic
const Icon: React.FC<IconProps> = ({ path, size, color, style, fillRule, clipRule, viewBox }) => {
	return (
		<Svg
			style={style}
			width={size}
			height={size}
			viewBox={viewBox}
			fill='none'>
			<Path d={path} fill={color} fillRule={fillRule} clipRule={clipRule} />
		</Svg>
	);
};

Icon.defaultProps = {
	size: '32px',
	color: '#F5F6FA',
	viewBox: '0 0 32 32'
};

export default Icon;
