import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH =
	'M8 0.805836L7.19429 0.00012207L4 3.19441L0.805714 0.00012207L0 0.805836L3.19429 4.00012L0 7.19441L0.805714 8.00012L4 4.80584L7.19429 8.00012L8 7.19441L4.80571 4.00012L8 0.805836Z';

export const CrossIcon: React.FC<IconProps> = ({
	size,
	color,
	style,
	viewBox = '0 0 8 8'
}) => {
	return (
		<Icon
			style={style}
			path={ICON_PATH}
			size={size}
			color={color}
			viewBox={viewBox}
		/>
	);
};
