import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ConnectivityScreen } from './connectivity-screen';
import { ScreenHeader } from '../../components';

const Stack = createStackNavigator();

export const VitalsScreen: React.FC = () => (
	<Stack.Navigator
		screenOptions={{
			header: ({ scene }) => (
				<ScreenHeader title={scene.descriptor.options.title} />
			)
		}}
	>
		<Stack.Screen
			name='Connectivity'
			options={{ title: 'Connectivity Vitals' }}
			component={ConnectivityScreen}
		/>
	</Stack.Navigator>
);
