import React, { Component, useEffect, useRef } from 'react';
import Svg, { Circle, G, Defs, LinearGradient, Stop } from 'react-native-svg';
import * as Styled from './styled';
import { LoadingIndicatorProps } from './types';
import { Animated } from 'react-native';

interface MyLinearGradientProps {
	percent: number;
}

export class AnimatedGradientDefs extends Component<MyLinearGradientProps> {
	state = {
		percent: 0
	};

	setNativeProps(props: MyLinearGradientProps) {
		const { percent } = props;
		this.setState({ percent });
	}

	render() {
		const { percent } = this.state;

		return (
			<Defs>
				<LinearGradient
					id='linear-gradient'
					x1='-100%'
					y1='0'
					x2='200%'
					y2='0'
					gradientUnits='userSpaceOnUse'
				>
					<Stop offset={percent - 1} stopColor={'#6AE9FC'} />
					<Stop offset={percent} stopColor={'rgba(3,15,28,0.7)'} />
				</LinearGradient>
			</Defs>
		);
	}
}
const AnimatedGradient = Animated.createAnimatedComponent(AnimatedGradientDefs);

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
	visible,
	testID
}) => {
	const offsetAnimated = useRef(new Animated.Value(0));

	const animate = () => {
		Animated.loop(
			Animated.sequence([
				Animated.timing(offsetAnimated.current, {
					useNativeDriver: false,
					toValue: 2,
					duration: 2500
				}),
				Animated.timing(offsetAnimated.current, {
					useNativeDriver: false,
					toValue: 0,
					duration: 2500
				})
			])
		).start();
	};

	useEffect(() => {
		animate();
	}, []);

	return (
		<Styled.Container visible={visible} testID={testID}>
			<Svg viewBox='0 0 32 17' style={{ width: 80, height: 42.5 }}>
				<AnimatedGradient percent={offsetAnimated.current} />

				<G fill='url(#linear-gradient)'>
					<Circle cx='10' cy='10' r='2' />
					<Circle cx='17' cy='10' r='2' />
					<Circle cx='24' cy='10' r='2' />
				</G>
			</Svg>
		</Styled.Container>
	);
};
