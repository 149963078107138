import * as AuthSession from 'expo-auth-session';
import Constants from 'expo-constants';
import { Platform } from 'react-native';
import { UserAuthType, IntrospectionResult, TokenDelegatesType, UserDelegatesType, ClientBrandType } from '../types/user-auth';
import fetch from 'cross-fetch';
import * as authStore from './auth-store';
import Cookies from 'universal-cookie';

export interface OktaAuthResponseType {
	type: 'error' | 'success' | 'cancel' | 'dismiss' | 'locked';
	errorCode: string | null;
	error?: AuthSession.AuthError | null;
	params: {
			[key: string]: string;
	};
	authentication: AuthSession.TokenResponse | null;
	url: string;
}

export interface RequestOktaType {
	promptAsync: (options?: AuthSession.AuthRequestPromptOptions | undefined) =>
		Promise<AuthSession.AuthSessionResult | void>,
	setError: (error: boolean) => Promise<void>,
	useProxy: boolean,
	tokenDelegates: TokenDelegatesType,
	userDelegates: UserDelegatesType,
	redirectUri: string,
	discovery?: AuthSession.DiscoveryDocument | null,
	request?: AuthSession.AuthRequest | null
}

export interface RequestOktaTypeResolve {
	requestAuthResolve: () => Promise<void>
}

export interface SessionDelegatesType {
	tokenDelegates: TokenDelegatesType,
	userDelegates: UserDelegatesType,
	discovery?: AuthSession.DiscoveryDocument | null
}

export const requestOktaAuth = ({
	promptAsync,
	setError,
	tokenDelegates,
	userDelegates,
	useProxy,
	redirectUri,
	discovery,
	request }: RequestOktaType): RequestOktaTypeResolve => {

	const requestAuthResolve = async () => {
		const res = await promptAsync({ useProxy }) as OktaAuthResponseType;
		if (res?.type === 'error' || res?.type === 'dismiss') {
			setError(true);
			return;
		}

		if (discovery && request) {
			// Exchange the code for the access token and the refresh token
			const tokenResponse = await AuthSession.exchangeCodeAsync({
				code: res.params.code,
				clientId: Constants.manifest.extra?.oktaAuth?.clientId,
				redirectUri,
				extraParams: {
					code_verifier: request.codeVerifier || '',
				},
			}, discovery);

			await storeSession(tokenResponse);
			await verifySession({ tokenDelegates, userDelegates, discovery });
		}
	};
	return { requestAuthResolve };
};

export const introspectToken = async (token: string): Promise<IntrospectionResult> => {
	const isWeb = Platform.select({ web: true, default: false });
	const extraParams = isWeb ? '' : `?client_id=${Constants.manifest.extra?.oktaAuth?.clientId}&token=${token}`;
	const introspectEndpoint = `${Constants.manifest.extra?.oktaAuth?.discovery}/v1/introspect${extraParams}`;
	const data = {
		client_id: Constants.manifest.extra?.oktaAuth?.clientId,
		token
	};
	const response = await fetch(introspectEndpoint, {
		method: 'POST',
		body: new URLSearchParams(data),
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
	return await response.json() as IntrospectionResult;
};

export const setReleaseChannel = async (token: string|undefined): Promise<void> => {
	console.log('setReleaseChannel', token);
	if (token) {
		const response = await fetch('https://m5w3pdzymj.execute-api.us-east-2.amazonaws.com/version', {
			mode: 'cors',
			headers: {
				'x-particle-bearer-token': token
			}
		});
		const json = await response.json();
		const cookies = new Cookies();
		if (cookies.get('release-channel') !== json.releaseChannel) {
			console.log(`setting release channel=${json.releaseChannel}`);
			cookies.set('release-channel', json.releaseChannel, { path: '/' });
			console.log(`reloading`);
			window.location.reload();
		} else {
			console.log(`release channel already set=${json.releaseChannel}`);
		}
	}
};

export const isTokenValid = async (token: string): Promise<boolean> => {
	const result = await introspectToken(token);
	return result.active;
};

export const fetchBranding = async (): Promise<ClientBrandType> => {
	const resp = await fetch('https://m5w3pdzymj.execute-api.us-east-2.amazonaws.com/branding');
	return await resp.json();
};

export const storeSession = async (tokenResponse: AuthSession.TokenResponse): Promise<void> => {
	const { accessToken, refreshToken } = tokenResponse;
	await authStore.setAccessToken(accessToken);
	await authStore.setRefreshToken(refreshToken);
};

export const verifySession = async ({
	tokenDelegates, userDelegates, discovery
}: SessionDelegatesType): Promise<void> => {
	const accessToken = await authStore.getAccessToken();
	if (!accessToken) {
		tokenDelegates.clearToken();
		userDelegates.clearUser();
		return;
	}

	const result = await introspectToken(accessToken);

	if (!result.active) {
		await clearSession();
		tokenDelegates.clearToken();
		userDelegates.clearUser();
		return;
	}

	await setReleaseChannel(accessToken);
	await tokenDelegates.setToken(accessToken);
	await userDelegates.setUser(result as UserAuthType);

	// Schedule token refresh
	if (result.exp) {
		const expiresIn = (result.exp * 1000) - Date.now();
		const refreshBefore = Constants.manifest.extra?.oktaAuth?.refreshBefore || 60;
		const refreshIn = Math.max(0, expiresIn - (refreshBefore * 1000));
		setTimeout(() => {
			refreshSession({ tokenDelegates, userDelegates, discovery });
		}, refreshIn);
	}
};

export const clearSession = async (): Promise<void> => {
	await authStore.clearAccessToken();
	await authStore.clearRefreshToken();
};

export const refreshSession = async ({
	tokenDelegates, userDelegates, discovery
}: SessionDelegatesType): Promise<void> => {
	const refreshToken = await authStore.getRefreshToken();
	if (refreshToken && discovery) {
		const tokenResponse = await AuthSession.refreshAsync({
			clientId: Constants.manifest.extra?.oktaAuth?.clientId,
			refreshToken
		}, discovery);

		await storeSession(tokenResponse);
		await verifySession({ tokenDelegates, userDelegates, discovery });
	}
};

export const revokeSession = async(discovery: AuthSession.DiscoveryDocument | null): Promise<void> => {
	const token = await authStore.getAccessToken();
	if (token && discovery) {
		await AuthSession.revokeAsync({
			clientId: Constants.manifest.extra?.oktaAuth?.clientId,
			token
		}, discovery);
		await clearSession();
	}
};
