import React from 'react';
import { Pressable } from 'react-native';
import { CrossIcon, SearchIcon } from '../../..';
import * as Styled from './styled';
import { SearchInputProps } from './types';

export const SearchInput: React.FC<SearchInputProps> = ({
	onSubmit,
	onChange,
	onClear,
	value,
	placeholder
}) => {
	return (
		<Styled.SearchInputContainer>
			<Pressable onPress={onSubmit} style={{ margin: 2 }}>
				<SearchIcon />
			</Pressable>
			<Styled.SearchInput
				placeholderTextColor='#bbbdc4'
				value={value}
				placeholder={placeholder}
				onChangeText={onChange}
				onSubmitEditing={onSubmit} />
			{
				value
					? (
						<Pressable onPress={onClear}>
							<CrossIcon style={{ height: 8 }} />
						</Pressable>
					)
					: null
			}
		</Styled.SearchInputContainer>
	);
};
