import React from 'react';
import * as Styled from './styled';
import { ConnectivityLevelDataTable } from '../../../components/ui/data-tables/connectivity-level';
import { FleetConnectivityDeviceCounter } from '../../../components/device-counter';
import { ConnectivityLevelChart, Spacer } from '../../../components/ui';
import {Title} from "../../../components/ui/type/title/styled";


export const ConnectivityScreen: React.FC = () => {
	return (
		<Styled.Container>
			<FleetConnectivityDeviceCounter />
			<Spacer size='medium' direction='vertical' />
			<ConnectivityLevelChart />
			<Spacer size='medium' direction='vertical' />
			<ConnectivityLevelDataTable />
		</Styled.Container>
	);
};
