/* istanbul ignore file */
// TODO(carlos): remove this ignore statement and test the on hover behavior
import React, { useEffect, useState, useRef } from 'react';
import { useTheme } from 'styled-components/native';
import {
	VictoryChart,
	VictoryLine,
	VictoryAxis,
	VictoryVoronoiContainer,
	VictoryTooltip,
	VictoryScatter
} from 'victory';
import { MultiLineChartType } from './types';
import { sampleData } from './sampleData';
import { MAX_DATA_POINTS_WEB } from './constants';
import { ArrowButton } from './arrow-button';
import { FadeAxis } from './fade-axis';
import { formatYTick, getTicksNumberForChartWidth } from './utils';

const NullComponent: React.FC = () => null;

export const MultiLineChart: MultiLineChartType = ({
	initialWidth,
	height,
	data,
	getX,
	formatTick,
	dataAccessors,
	flyoutComponent,
	pagination,
	testID,
	maxDataPoints = MAX_DATA_POINTS_WEB,
	onResize
}) => {
	const theme = useTheme();
	const containerRef = useRef<HTMLDivElement>(null);
	const [width, setWidth] = useState(initialWidth);
	const [activePointIndex, setActivePointIndex] = useState<number | null>(null);
	const notifyResize = (width: number | undefined, height: number) => {
		if (onResize) {
			onResize(width || 0, height);
		}
	};
	useEffect(() => {
		// TODO(carlos): Debounce this handler
		const handleResize = () => {
			setWidth(containerRef.current?.clientWidth || initialWidth);
			notifyResize(containerRef.current?.clientWidth || initialWidth, height);
		};
		setWidth(containerRef.current?.clientWidth || initialWidth);
		notifyResize(containerRef.current?.clientWidth || initialWidth, height);
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	const sampledData = sampleData(data, maxDataPoints);
	const ticksNumber = getTicksNumberForChartWidth(width);
	const ticks = sampleData(sampledData, ticksNumber).map(getX);
	const activeScatterPoints = sampledData.filter(
		(item, index) => index === activePointIndex
	);
	const activeVerticalLinePoints = sampledData
		.filter(
			(item, index) =>
				index === activePointIndex || index === sampledData.length - 1
		)
		.map((item) => getX(item));

	return (
		<div
			style={{
				height: `${height}px`
			}}
			ref={containerRef}
			onMouseLeave={() => setActivePointIndex(null)}
		>
			{data.length > 0 && <div data-testid={testID}></div>}
			<VictoryChart
				height={height}
				width={width}
				theme={theme.charts}
				domainPadding={{ y: 50, x: 0 }}
				padding={{ bottom: 50, top: 0, left: 40, right: 20 }}
				containerComponent={
					<VictoryVoronoiContainer
						voronoiBlacklist={['vertical-line', 'x-axis-acatter']}
						voronoiPadding={{ bottom: 50 }}
						onActivated={(points) => {
							if (points.length > 0) {
								setActivePointIndex(points[0].eventKey);
							}
						}}
						labelComponent={
							<VictoryTooltip
								flyoutComponent={flyoutComponent}
								labelComponent={<NullComponent />}
							/>
						}
						labels={() => ' '}
					/>
				}
			>
				<VictoryAxis tickFormat={formatTick} tickValues={ticks} />
				<VictoryAxis tickFormat={formatYTick} dependentAxis />
				{setActivePointIndex !== null && (
					<VictoryLine
						name='vertical-line'
						x={() => activeVerticalLinePoints[0]}
						style={{
							data: {
								opacity: () => (activePointIndex === null ? 0 : 1)
							}
						}}
					/>
				)}
				{dataAccessors.map((accessor) => (
					<VictoryLine
						key={accessor.accessorKey.toString()}
						style={{
							data: {
								opacity: ({ active }) =>
									active || activePointIndex === null ? 1 : 0.3,
								stroke: accessor.color
							}
						}}
						data={sampledData}
						y={accessor.accessorKey.toString()}
						x={getX}
					/>
				))}
				{dataAccessors.map((accessor) => (
					<VictoryScatter
						key={accessor.accessorKey.toString()}
						style={{
							data: {
								opacity: ({ active }) => (active ? 1 : 0),
								fill: accessor.color,
								stroke: theme.components.connectivityLineChart.background,
								strokeWidth: 4
							}
						}}
						data={activeScatterPoints}
						size={9}
						y={accessor.accessorKey.toString()}
						x={getX}
					/>
				))}
				<FadeAxis width={width || 0} height={height} />
				<ArrowButton
					x={40}
					y={height - 45}
					direction='left'
					disabled={!pagination.hasPrev}
					onPress={pagination.onPrev}
				/>
				<ArrowButton
					x={(width || 40) - 40}
					y={height - 45}
					direction='right'
					disabled={!pagination.hasNext}
					onPress={pagination.onNext}
				/>
			</VictoryChart>
		</div>
	);
};
