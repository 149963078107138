import { useState } from 'react';
import { useFleetConnectivityInfo } from '../../../../hooks';
import { DataTableConfigProps, ConnectivityLevelState } from './types';

export const useConnectivityLevelState = (): ConnectivityLevelState => {
	const height = 215;
	const [searchValue, setSearchValue] = useState('');
	const [dataTableConfig, setDataTableConfig] = useState<DataTableConfigProps>({
		sorting: {
			order: 'asc',
			column: 'connectivityLevel'
		},
		pagination: {
			pageSize: 20,
			index: 0
		},
		search: {
			value: ''
		}
	});

	const { loading, error, data, previousData } = useFleetConnectivityInfo({
		orderBy: {
			[dataTableConfig.sorting.column]: dataTableConfig.sorting.order
		},
		pagination: {
			first: dataTableConfig.pagination.pageSize,
			offset: dataTableConfig.pagination.index
		},
		filter: {
			keyword: dataTableConfig.search.value
		}
	});
	const pagination = {
		index: dataTableConfig.pagination.index,
		pageSize: dataTableConfig.pagination.pageSize,
		pageSizes: ['20', '50', '100'],
		onNext: () => {
			setDataTableConfig((prevValue) => ({
				...prevValue,
				pagination: {
					...prevValue.pagination,
					index: prevValue.pagination.index + prevValue.pagination.pageSize
				}
			}));
		},
		onPrev: () => {
			setDataTableConfig((prevValue) => ({
				...prevValue,
				pagination: {
					...prevValue.pagination,
					index: prevValue.pagination.index - prevValue.pagination.pageSize
				}
			}));
		},
		onPageChange: (itemValue: unknown) => {
			const value = itemValue as string;
			setDataTableConfig((prevValue) => ({
				...prevValue,
				pagination: {
					pageSize: parseInt(value, 10),
					index: 0
				}
			}));
		}
	};
	const sorting = {
		order: dataTableConfig.sorting.order,
		onSort: (name: string) => {
			setDataTableConfig((prevValue) => {
				return {
					...prevValue,
					sorting: {
						column: name,
						order: prevValue.sorting.order === 'asc' ? 'desc' : 'asc'
					},
					pagination: {
						index: 0,
						pageSize: prevValue.pagination.pageSize
					}
				};
			});
		}
	};
	const search = {
		value: searchValue,
		onSubmit: () => {
			setDataTableConfig((prevValue) => {
				return {
					sorting: {
						column: 'connectivityLevel',
						order: 'asc'
					},
					pagination: {
						index: 0,
						pageSize: prevValue.pagination.pageSize
					},
					search: { value: searchValue }
				};
			});
		},
		onChange: (value: string) => {
			if (!value.length) {
				setDataTableConfig((prevValue) => {
					return {
						sorting: {
							column: 'connectivityLevel',
							order: 'asc'
						},
						pagination: {
							index: 0,
							pageSize: prevValue.pagination.pageSize
						},
						search: { value: '' }
					};
				});
			}
			setSearchValue(value);
		},
		onClear: () => {
			setSearchValue('');
			setDataTableConfig((prevValue) => {
				return {
					sorting: {
						column: 'connectivityLevel',
						order: 'asc'
					},
					pagination: {
						index: 0,
						pageSize: prevValue.pagination.pageSize
					},
					search: { value: '' }
				};
			});
		}
	};
	const fleetConnectivity = () => {
		if (loading) {
			return previousData?.fleetConnectivity;
		}
		return data?.fleetConnectivity;
	};
	return {
		height,
		fleetConnectivity: fleetConnectivity(),
		error,
		loading,
		pagination,
		sorting,
		search
	};
};
