import React from 'react';
import truncate from 'lodash/truncate';
import { format } from 'date-fns';
import * as Styled from './styled';
import { Spacer, Title } from '../..';
import { View } from 'react-native';
import { Paginator } from '../../paginator/paginator';
import { Col, Row } from 'react-native-easy-grid';
import { Headers } from './headers';
import { CellProps, Column, Cell as CellProp, Sorting } from './types';
import { Paragaraph } from '../../type/paragraph/styled';
import colors from '../../colors/colors';
import { LoadingIndicator } from '../../loading-indicator';
import { useConnectivityLevelState } from './use-connectivity-level-state';
import { MediaQueries, useMediaQueries } from '../../media-queries/use-media-queries';
import { EmptyMessage } from '../../empty-message';
import { SearchInput } from './search-input';
import { Connectivity, FleetConnectivity } from '../../../../hooks/use-fleet-connectivity-info';


const COLUMNS: Column[] = [
	{
		label: 'Device',
		name: 'deviceName',
		style: {
			flexGrow: 0,
			flexShrink: 0,
			flexBasis: 236,
		},
		displaySortIcon: false,
		render(device) {
			return <DeviceCell device={device} />;
		}
	},
	{
		label: 'Last Heard',
		name: 'lastHeard',
		style: {
			flexGrow: 0,
			flexShrink: 0,
			flexBasis: 104,
		},
		displaySortIcon: false,
		render(device) {
			return <LastHeardCell device={device} />;
		}
	},
	{
		label: 'Connectivity',
		name: 'connectivityLevel',
		style: {
			flexGrow: 0,
			flexShrink: 0,
			flexBasis: 90,
		},
		displaySortIcon: false,
		render(device) {
			return (
				<ConnectivityCell device={device} />
			);
		}
	},
	{
		label: 'Connectivity Type',
		name: 'connectivityType',
		style: {
			flex: 1
		},
		displaySortIcon: false,
		render(device) {
			return (
				<Cell field={device.connectivityType} content={
					<View>
						<Styled.CellText>{device.connectivityType}</Styled.CellText>
						<Styled.CellText>{device.platform} {device.radioAccessTechnology}</Styled.CellText>
					</View>
				} />
			);
		}
	},
	{
		label: 'Operator',
		name: 'operator',
		style: {
			flex: 1
		},
		displaySortIcon: false,
		render(device) {
			return (
				<Cell field={device.operator} content={
					<Styled.CellText>{device.operator}</Styled.CellText>
				} />
			);
		}
	},
	{
		label: 'Cell Area Location',
		name: 'cellAreaLocation',
		style: {
			flex: 1
		},
		displaySortIcon: false,
		render(device) {
			return (
				<Cell field={device.cellAreaLocation} content={
					<Styled.CellText>{device.cellAreaLocation}</Styled.CellText>
				} />
			);
		}
	},
	{
		label: 'Device Group',
		name: 'groups',
		style: {
			flex: 1
		},
		displaySortIcon: false,
		render(device) {
			return (
				<Cell field={device.groups.length} content={
					<Styled.CellText>{device.groups}</Styled.CellText>
				} />
			);
		}
	},
	{
		label: 'Product',
		name: 'product',
		style: {
			flex: 1
		},
		displaySortIcon: false,
		render(device) {
			return (
				<Cell field={device.product} content={
					<Styled.CellText>{device.product}</Styled.CellText>
				} />
			);
		}
	},
	{
		label: 'DeviceOS',
		name: 'deviceOSVersion',
		style: {
			flexGrow: 0,
			flexShrink: 0,
			flexBasis: 80,
		},
		displaySortIcon: false,
		render(device) {
			return (
				<Cell field={device.deviceOSVersion} content={
					<Styled.CellText>{device.deviceOSVersion}</Styled.CellText>
				} />
			);
		}
	},
	{
		label: 'Firmware',
		name: 'firmwareVersion',
		style: {
			flexGrow: 0,
			flexShrink: 0,
			flexBasis: 80,
		},
		displaySortIcon: false,
		render(device) {
			return (
				<Cell field={device.firmwareVersion} content={
					<Styled.CellText>{device.firmwareVersion}</Styled.CellText>
				} />
			);
		}
	}
];

const Cell = ({ field, content }: CellProp) => {
	if (field) {
		return <View style={{ paddingLeft: 8 }}>{content}</View>;
	}
	return (
		<View>
			<Styled.CellText>-</Styled.CellText>
		</View>
	);
};

const connectivityColors = {
	'No Data': colors.fuchsia['200'],
	'Poor': colors.fuchsia['100'],
	'Fair': colors.teal['100'],
	'Good': colors.teal['200']
};

const formatLastHeard = (field: string) => {
	const date = format(new Date(field), 'yyyy-MM-dd');
	const hour = format(new Date(field), 'HH:MM');
	return {
		date,
		hour
	};
};

const resetColumnState = () => {
	for (const c of COLUMNS) {
		c.displaySortIcon = false;
	}
};

const DeviceCell: React.FC<CellProps> = ({ device }) => {
	return (
		<Row>
			<Col size={10}>
				<Cell field={device.deviceName} content={
					<Title size='small'>
						{truncate(device.deviceName || '', { 'length': 22 })}
					</Title>
				} />
				<Cell field={device.deviceId} content={
					<Paragaraph size='small' style={{ color: colors.grey['300'] }}>
						ID {device.deviceId}
					</Paragaraph>
				} />
				<Cell field={device.deviceSerialNumber} content={
					<Paragaraph size='small' style={{ color: '#BBBDC4' }}>
						SN {device.deviceSerialNumber}
					</Paragaraph>
				} />
			</Col>
			<Col style={{ alignItems: 'center' }}>
				<Styled.OnlineDot online={device.online} style={{ position: 'absolute', top: 5 }} />
			</Col>
		</Row>
	);
};

const LastHeardCell: React.FC<CellProps> = ({ device }) => {
	if (!device.lastHeard) {
		return null;
	}
	return (
		<Cell field={device.lastHeard} content={
			<View>
				<Styled.CellText>{formatLastHeard(device.lastHeard).date}</Styled.CellText>
				<Styled.CellText>{formatLastHeard(device.lastHeard).hour} UTC</Styled.CellText>
			</View>
		} />
	);
};

const connectivityLevelTextColor = (device: Connectivity) => {
	return {
		inverted: device.connectivityLevel !== 'No Data'
	};
};

const ConnectivityCell: React.FC<CellProps> = ({ device }) => {
	return (
		<Styled.ConnectivityCell backgroundColor={connectivityColors[device.connectivityLevel]}>
			<Cell field={device.connectivityLevel} content={
				<Styled.CellText {...connectivityLevelTextColor(device)} style={{ fontFamily: 'Inter_600SemiBold' }}>
					{device.connectivityLevel}
				</Styled.CellText>
			} />
			<Cell field={device.connectivityPercent} content={
				<Styled.CellText {...connectivityLevelTextColor(device)}>
					{device.connectivityPercent || 0}%
				</Styled.CellText>
			} />
		</Styled.ConnectivityCell>
	);
};

const MobileDataTable = ({ fleetConnectivity, mediaQueries }: {
	fleetConnectivity: FleetConnectivity | undefined, mediaQueries: MediaQueries
}) => {
	return (
		<View>
			{fleetConnectivity?.connectivity &&
				fleetConnectivity?.connectivity.map((device: Connectivity, index) => {
					return (
						<View style={{ paddingLeft: 17, paddingRight: 17 }} key={index}>
							<Spacer size='medium' direction='vertical' />
							<Row>
								<Col size={75} style={{ paddingBottom: 24 }}>
									<DeviceCell device={device} />
								</Col>
								<Spacer size='large' direction='horizontal' />
								<Col size={25} style={{ justifyContent: 'center' }}>
									<Styled.MobileConnectivityLabel
										backgroundColor={connectivityColors[device.connectivityLevel]}>
										<Styled.CellText
											{...connectivityLevelTextColor(device)}
											style={{ fontFamily: 'Inter_600SemiBold' }}>
											{device.connectivityLevel}
										</Styled.CellText>
										<Styled.CellText
											style={{ marginLeft: 'auto', fontFamily: 'Inter_600SemiBold' }}
											{...connectivityLevelTextColor(device)}>
											{device.connectivityPercent || 0}%
										</Styled.CellText>
									</Styled.MobileConnectivityLabel>
								</Col>
							</Row>
							<Row style={{ marginTop: 17, paddingLeft: 8 }}>
								<Col>
									{
										device.lastHeard && (
											<View>
												<Styled.MobileLabel type='bold'>Last Heard</Styled.MobileLabel>
												<Styled.CellText>
													{formatLastHeard(device.lastHeard).date} {''}
													{formatLastHeard(device.lastHeard).hour} UTC
												</Styled.CellText>
											</View>
										)
									}
									<Spacer size='extraSmall' direction='vertical' />
									<View>
										<Styled.MobileLabel type='bold'>Connectivity Type</Styled.MobileLabel>
										<Styled.CellText>
											{device.connectivityType}
										</Styled.CellText>
									</View>
								</Col>
								<Col>
									<View>
										<Styled.MobileLabel type='bold'>Operator</Styled.MobileLabel>
										<Styled.CellText>
											{device.operator}
										</Styled.CellText>
									</View>
									<Spacer size='extraSmall' direction='vertical' />
									<View>
										<Styled.MobileLabel type='bold'>Cell Area Location</Styled.MobileLabel>
										<Styled.CellText>
											{device.cellAreaLocation}
										</Styled.CellText>
									</View>
								</Col>
								{
									!mediaQueries.isMobile && (
										<Col>
											<View>
												<Styled.MobileLabel type='bold'>Device Group</Styled.MobileLabel>
												<Styled.CellText>
													{device.groups}
												</Styled.CellText>
											</View>
											<Spacer size='extraSmall' direction='vertical' />
											<View>
												<Styled.MobileLabel type='bold'>Product</Styled.MobileLabel>
												<Styled.CellText>
													{device.product}
												</Styled.CellText>
											</View>
										</Col>
									)
								}
							</Row>
							<Spacer size='medium' direction='vertical' />
							<Styled.Separator />
						</View>
					);
				})}
		</View>
	);
};

const DesktopDataTable = ({ fleetConnectivity, sorting }: {
	fleetConnectivity: FleetConnectivity | undefined, sorting: Sorting
}) => {
	return (
		<View style={{ paddingLeft: 17, paddingRight: 17 }}>
			<Row>
				{
					COLUMNS.map((column, index) => {
						return (
							<Headers
								key={index}
								name={column.name}
								label={column.label}
								displaySortIcon={column.displaySortIcon}
								onPress={(name) => {
									for (const c of COLUMNS) {
										c.displaySortIcon = c.name === column.name;
									}

									sorting.onSort(name);
								}}
								style={column.style}
								sortOrder={sorting.order} />
						);
					})
				}
			</Row>
			<View>
				{
					fleetConnectivity?.connectivity &&
					fleetConnectivity?.connectivity.map((device, index) => {
						return (
							<Row key={index} testID={`row-${index}`}>
								{
									COLUMNS.map((column, index) => {
										return (
											<Col
												key={index}
												style={column.style}>
												<Styled.Cell>
													{column.render(device)}
												</Styled.Cell>
											</Col>
										);
									})
								}
							</Row>
						);
					})
				}
			</View>
		</View>
	);
};

export const ConnectivityLevelDataTable: React.FC = () => {
	const {
		height,
		fleetConnectivity,
		loading,
		error,
		pagination,
		sorting,
		search
	} = useConnectivityLevelState();
	const mediaQueries = useMediaQueries();

	const emptyMessageValidation = !fleetConnectivity
		|| Boolean(error)
		|| (fleetConnectivity.connectivity && !fleetConnectivity.connectivity.length);
	const isEmptyMessageVisible = !loading && emptyMessageValidation;

	let pageCount = 0;

	if (fleetConnectivity) {
		pageCount = fleetConnectivity?.pageInfo.totalCount;
	}

	return (
		<Styled.DataTableContainer>
			<Styled.DataTableTitleContainer>
				<Title size='medium'>Cellular Devices</Title>
			</Styled.DataTableTitleContainer>
			<Styled.Separator />
			<Styled.ToolBar mediaQueries={mediaQueries}>
				<Styled.SearchInputContainer mediaQueries={mediaQueries}>
					<SearchInput
						placeholder='Search for devices'
						value={search.value}
						onChange={(value) => {
							search.onChange(value);
							if (!value.length) {
								resetColumnState();
							}
						}}
						onSubmit={() => {
							search.onSubmit();
							resetColumnState();
						}}
						onClear={() => {
							search.onClear();
							resetColumnState();
						}} />
				</Styled.SearchInputContainer>
				{
					mediaQueries.isMobile && <Spacer size='small' direction='vertical' />
				}
				<Styled.PaginatorContainer mediaQueries={mediaQueries}>
					<Paginator
						count={pageCount}
						index={pagination.index}
						pageSizes={pagination.pageSizes}
						pageSize={pagination.pageSize}
						onNext={() => {
							pagination.onNext();
						}}
						onPrevious={() => {
							pagination.onPrev();
						}}
						onPageSizeChanged={(itemValue) => {
							pagination.onPageChange(itemValue);
						}} />
				</Styled.PaginatorContainer>
			</Styled.ToolBar>
			{
				isEmptyMessageVisible
					? <EmptyMessage height={height} />
					: (
						<View>
							{
								mediaQueries.isTablet || mediaQueries.isMobile
									? (
										<MobileDataTable
											mediaQueries={mediaQueries}
											fleetConnectivity={fleetConnectivity} />
									)
									: (
										<DesktopDataTable
											sorting={sorting}
											fleetConnectivity={fleetConnectivity} />
									)
							}
							<LoadingIndicator visible={loading} />
						</View>
					)
			}
		</Styled.DataTableContainer>
	);
};
