import React from 'react';
import { useTheme } from 'styled-components/native';
import { MultiLineChart } from '../multi-line-chart';
import { SegmentedControl } from '../../segmented-control';
import * as Styled from './styled';
import { Title } from '../../type/title/styled';
import { formatTick } from './utils';
import { ConnectivityLevelFlyout } from './connectivity-level-flyout';
import { LoadingIndicator } from '../../loading-indicator';
import { useConnectivityChartState } from './use-connectivity-chart-state';
import { EmptyMessage } from '../../empty-message';
import { Spacer } from '../../spacer';

export const ConnectivityLevelChart: React.FC = () => {
	const theme = useTheme();
	const {
		width,
		height,
		setWidth,
		connectivitySummary,
		loading,
		error,
		timeWindowButtonHandlers,
		pagination
	} = useConnectivityChartState();

	const getContent = () => {
		const isEmptyMessageVisible =
			!loading && (!connectivitySummary.length || Boolean(error));

		if (isEmptyMessageVisible) {
			return <EmptyMessage height={height} />;
		}

		return (
			<MultiLineChart
				height={height}
				data={connectivitySummary}
				getX={(item) => new Date(item.timeStamp).getTime()}
				testID='qa-cellular-connectivity-data'
				formatTick={formatTick}
				flyoutComponent={
					<ConnectivityLevelFlyout chartSize={{ height, width }} />
				}
				dataAccessors={[
					{
						color: theme.components.connectivityLineChart.dataSetColors.good,
						accessorKey: 'good'
					},
					{
						color: theme.components.connectivityLineChart.dataSetColors.fair,
						accessorKey: 'fair'
					},
					{
						color: theme.components.connectivityLineChart.dataSetColors.poor,
						accessorKey: 'poor'
					},
					{
						color: theme.components.connectivityLineChart.dataSetColors.noData,
						accessorKey: 'noData'
					}
				]}
				onResize={setWidth}
				pagination={pagination}
			/>
		);
	};

	return (
		<Styled.Container testID='qa-cellular-connectivity-chart'>
			<Styled.TitleBar>
				<Title size='medium'>
					Cellular Devices by Connectivity Level Over Time
					<Spacer direction='horizontal' size='extraSmall' />
					<Styled.TitleExtra type='regular'>hours in UTC</Styled.TitleExtra>
				</Title>
			</Styled.TitleBar>
			<Styled.Body>
				<Styled.TimeWindowSegmentedControlContainer>
					<SegmentedControl
						initialItemId='1day'
						items={[
							{
								id: '12hours',
								label: '12 hours',
								onPress: timeWindowButtonHandlers['12hours']
							},
							{
								id: '1day',
								label: 'Day',
								onPress: timeWindowButtonHandlers['1day']
							},
							{
								id: '1week',
								label: 'Week',
								onPress: timeWindowButtonHandlers['1week']
							},
							{
								id: '1month',
								label: 'Month',
								onPress: timeWindowButtonHandlers['1month']
							}
						]}
					/>
				</Styled.TimeWindowSegmentedControlContainer>
				<Styled.ChartContainer>
					{getContent()}
					{loading && (
						<LoadingIndicator
							visible
							testID='qa-cellular-connectivity-chart-loading'
						/>
					)}
				</Styled.ChartContainer>
			</Styled.Body>
		</Styled.Container>
	);
};
