import { gql } from '@apollo/client';
import { getClient } from './graphql-client';

export const GET_ACCESS_TOKEN_FRAGMENT = gql`
	fragment getAccessToken on authentication {
		accessToken
	}
`;

export const SET_ACCESS_TOKEN_FRAGMENT = gql`
	fragment setAccessToken on authentication {
		accessToken
	}
`;

export const GET_REFRESH_TOKEN_FRAGMENT = gql`
	fragment getRefreshToken on authentication {
		refreshToken
	}
`;

export const SET_REFRESH_TOKEN_FRAGMENT = gql`
	fragment setRefreshToken on authentication {
		refreshToken
	}
`;

export const id = 'authentication';

export async function getAccessToken(): Promise<string|null> {
	const client = await getClient();
	if (client) {
		const result = client.readFragment({
			id,
			fragment: GET_ACCESS_TOKEN_FRAGMENT
		});
		return result ? result.accessToken : null;
	}
	return null;
}

export async function setAccessToken(accessToken?: string|null): Promise<void> {
	const client = await getClient();
	if (client) {
		client.writeFragment({
			id,
			fragment: SET_ACCESS_TOKEN_FRAGMENT,
			data: {
				accessToken,
				__typename: id
			}
		});
	}
}

export async function clearAccessToken(): Promise<void> {
	await setAccessToken(null);
}

export async function getRefreshToken(): Promise<string|null> {
	const client = await getClient();
	if (client) {
		const result = client.readFragment({
			id,
			fragment: GET_REFRESH_TOKEN_FRAGMENT
		});
		return result ? result.refreshToken : null;
	}
	return null;
}

export async function setRefreshToken(refreshToken?: string|null): Promise<void> {
	const client = await getClient();
	if (client) {
		client.writeFragment({
			id,
			fragment: SET_REFRESH_TOKEN_FRAGMENT,
			data: {
				refreshToken,
				__typename: id
			}
		});
	}
}

export async function clearRefreshToken(): Promise<void> {
	await setRefreshToken(null);
}
