import { PropsWithChildren } from '@testing-library/react-hooks/node_modules/@types/react';
import React from 'react';
import { Modal as RNModal } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/native';
import { IconButton } from '../buttons';
import { Title } from '../type';
import * as Styled from './styled';
import { HeaderProps, ModalProps } from './types';

export const Header: React.FC<HeaderProps> = ({ title, onRequestClose }) => (
	<Styled.Header>
		<Title size='small'>{title}</Title>
		<IconButton iconName='CrossIcon' size='12px' onPress={onRequestClose} />
	</Styled.Header>
);

export const Modal = ({
	isOpen,
	onRequestClose,
	children,
	minWidth = '700px',
	minHeight = '432px'
}: PropsWithChildren<ModalProps>) => {
	const { screens } = useTheme();
	const isDesktop = useMediaQuery({ minWidth: screens.md });
	const childrenWithProps = React.Children.map(children, (child) => {
		if (React.isValidElement(child) && child.type === Header) {
			return React.cloneElement(child, { onRequestClose });
		}
		return child;
	});
	const animationType = isDesktop ? 'fade' : 'slide';

	return (
		<RNModal transparent animationType={animationType} visible={isOpen}>
			<Styled.OverlayTouchable onPress={onRequestClose}>
				<Styled.Overlay />
			</Styled.OverlayTouchable>
			<Styled.Container
				isFullScreen={!isDesktop}
				minWidth={minWidth}
				minHeight={minHeight}
			>
				{childrenWithProps}
			</Styled.Container>
		</RNModal>
	);
};

Modal.Header = Header;
Modal.Body = Styled.Body;
Modal.Footer = Styled.Footer;
