import React from 'react';
import { useTheme } from 'styled-components/native';
import { CheckmarkIcon } from '../icons';
import { Tag } from '../tag';
import { SelectableTagProps } from './types';

export const SelectableTag: React.FC<SelectableTagProps> = ({
	label,
	selected,
	onPress
}) => {
	const theme = useTheme();
	const background = selected
		? theme.components.selectableTag.selected.background
		: theme.components.selectableTag.unselected.background;
	const foreground = selected
		? theme.components.selectableTag.selected.foreground
		: theme.components.selectableTag.unselected.foreground;

	return (
		<Tag
			background={background}
			foreground={foreground}
			leading={
				selected ? <CheckmarkIcon size='12px' color={foreground} /> : null
			}
			label={label}
			onPress={() => onPress(selected)}
		/>
	);
};
