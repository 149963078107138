import React from 'react';
import { CheckmarkIcon } from '../icons';
import { Label } from '../type/label/styled';
import * as Styled from './styled';
import { CheckboxProps } from './types';

export const Checkbox: React.FC<CheckboxProps> = ({
	value,
	label,
	checked,
	disabled,
	onPress
}) => {
	const handlePress = () => {
		if (onPress && !disabled) {
			onPress({ value, checked: !!checked });
		}
	};
	return (
		<Styled.Touchable onPress={handlePress}>
			<Styled.Container>
				<Styled.Check checked={checked}>
					{checked && <CheckmarkIcon size='12px' />}
				</Styled.Check>
				{label && (
					<Styled.LabelWrapper>
						<Label type='regular'>{label}</Label>
					</Styled.LabelWrapper>
				)}
			</Styled.Container>
		</Styled.Touchable>
	);
};
