import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH = 'M15.7 6l-.2.1-8 4.5a1 1 0 00-.4.5l-.1.5v8.7c0 .4.2.7.5.9l8.2 4.6h1.1l8-4.5c.3-.2.5-.6.5-1v-8.7-.2a1 1 0 00-.5-.6L16.4 6a1 1 0 00-.7 0zM17 16.5v7l6.4-3.6V13L17 16.5zM9 19.8l6.3 3.5v-6.7l-6.3-3.5v6.7zm1-8.3l6.2 3.4 6.1-3.4L16 8l-6.2 3.5z';

const DevicesIcon: React.FC<IconProps> = ({ size, color }) => {
	return <Icon path={ICON_PATH} size={size} color={color} fillRule='evenodd' clipRule='evenodd' />;
};

export default DevicesIcon;

