import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useDeviceCounterState } from './use-device-counter-state';
import { LoadingIndicator } from '../ui/loading-indicator';
import { UpdatedAgo } from '../ui/type/updated-ago';
import { Title } from '../ui/type/title/styled';
import { DeviceConnectivityLevelSpec } from './types';
import * as Styled from './styled';

const levels: Array<DeviceConnectivityLevelSpec> = [
	{
		name: 'noData',
		label: 'No Data',
		inverted: false
	},
	{
		name: 'poor',
		label: 'Poor',
		inverted: true
	},
	{
		name: 'fair',
		label: 'Fair',
		inverted: true
	},
	{
		name: 'good',
		label: 'Good',
		inverted: true
	}
];

export const FleetConnectivityDeviceCounter: React.FC = () => {
	const { start, setStart, data, loading } = useDeviceCounterState();
	const slim = useMediaQuery({ query: '(max-width: 990px)' });

	return (
		<Styled.Container>
			<Styled.Box slim={slim}>
				<Styled.TitleCell slim={slim} onClick={() => setStart(new Date())}>
					<Title size='medium'>
						Cellular Devices per Connectivity Level
					</Title>
					<UpdatedAgo from={start}/>
				</Styled.TitleCell>
				<Styled.TotalCell slim={slim}>
					<Styled.CellTitleText slim={slim}>
						Total Cellular Devices
					</Styled.CellTitleText>
					<Styled.CellBodyText slim={slim}>
						{renderCount(data?.total)}
					</Styled.CellBodyText>
				</Styled.TotalCell>
				{
					levels.map((level, index) => {
						const count = data ? data[level.name] : undefined;

						return (
							<Styled.LevelCell
								key={level.name}
								name={level.name}
								first={index === 0}
								last={index === levels.length - 1}
								slim={slim}
							>
								<Styled.CellTitleText inverted={level.inverted}>
									{level.label}
								</Styled.CellTitleText>
								<Styled.CellBodyText inverted={level.inverted}>
									{renderCount(count)}
								</Styled.CellBodyText>
							</Styled.LevelCell>
						);
					})
				}
			</Styled.Box>
			<LoadingIndicator visible={loading} />
		</Styled.Container>
	);
};

function renderCount(count: number | void): number | string {
	return typeof count === 'number' ? count : '-';
}

