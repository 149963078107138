import styled from 'styled-components/native';
import { Label } from '../..';
import colors from '../../colors/colors';
import { TableStylesProps, OnlineDotProps, ToolBarProps } from './types';

export const ToolBar = styled.View<ToolBarProps>`
	display: flex;
	flex-direction: ${({ mediaQueries }) => {
		if (mediaQueries.isMobile) {
			return 'column';
		}
		return 'row';
	}};
	padding 17px;
	margin: 20px;
`;

export const SearchInputContainer = styled.View<ToolBarProps>`
	width: ${({ mediaQueries }) => {
		if (mediaQueries.isMobile) {
			return '100%';
		}
		return '361px';
	}};
`;

export const PaginatorContainer = styled.View<ToolBarProps>`
	margin-left: ${({ mediaQueries }) => {
		if (!mediaQueries.isMobile) {
			return 'auto';
		}
		return 'initial';
	}};
`;

export const DataTableContainer = styled.View`
	padding: 17px 0;
	background-color: ${({ theme }) => theme.colors.primary};
	border-radius: 12px;
`;

export const DataTableTitleContainer = styled.View`
	padding-bottom: 17px;
	padding-left: 17px;
`;

export const Separator = styled.View`
	height: 1px;
	background-color: #284767;
	border-radius: 5px;
`;

export const DataTableSearchContainer = styled.View`
	padding: 17px;
`;

export const Header = styled.Pressable`
	color: ${({ theme }) => theme.components.dataTable.headerBackground};
	background-color: ${({ theme }) =>
		theme.components.dataTable.headerBackground};
	padding: 5px 8px;
	height: 40px;
	font-size: 12px;
	border-bottom-color: ${({ theme }) =>
		theme.components.dataTable.rowDividerColor};
	border-bottom-width: 2px;
	border-right-color: ${({ theme }) => theme.components.dataTable.background};
	border-right-width: 1px;
	justify-content: center;
`;

export const Cell = styled.View`
	background-color: ${({ theme }) => theme.components.dataTable.background};
	height: 63px;
	align-items: center;
	flex-direction: row;
	border-bottom-color: ${({ theme }) =>
		theme.components.dataTable.rowDividerColor};
	border-bottom-width: 1px;
`;

export const MobileConnectivityLabel = styled.View<TableStylesProps>`
	background-color: ${({ backgroundColor }) =>
		backgroundColor || colors.cobalt['700']};
	padding: 2px 4px;
	border-radius: 4px;
	width: 95px;
	display: flex;
	flex-direction: row;
`;

export const OnlineDot = styled.View<OnlineDotProps>`
	width: 9px;
	height: 9px;
	border-radius: 5px;
	background-color: ${({ online }) =>
		online ? colors.cyan['500'] : colors.grey['100']};
`;

export const ConnectivityCell = styled.View<TableStylesProps>`
	width: 100%;
	height: 100%;
	padding: 5px 0;
	background-color: ${(props) => props.backgroundColor || colors.cobalt['700']};
`;

export const CellText = styled.Text<TableStylesProps>`
	color: ${({ color, inverted, theme }) => {
		if (color) {
			return color;
		}

		if (inverted) {
			return theme.components.dataTable.rowTextColorInverted;
		}

		return theme.components.dataTable.rowTextColor;
	}};
	fontSize: ${(props) => (props.fontSize ? props.fontSize : '12px')}
	fontFamily: ${({ fontFamily, theme }) =>
		fontFamily ? fontFamily : theme.font.primary}
	lineHeight: 20px;
`;

export const ArrowIconContainer = styled.View`
	position: absolute;
	right: 10px;
	background-color: ${({ theme }) => theme.components.dataTable.iconBackground};
	height: 20px;
	width: 21px;
	border-radius: 20px;
`;

export const MobileLabel = styled(Label)`
	color: ${colors.grey['300']};
`;
