import React, { useState, useEffect } from 'react';
import styled from 'styled-components/native';
import formatDistance from 'date-fns/formatDistance';
import Label from './label/label';


// TODO (mirande): colors should be part of the theme, `type='regular'` should
// be the default
const TitleExtra = styled(Label)`
	color: #bbbdc4;
`;

export interface UpdatedAgoProps {
	from: Date;
	refreshRate?: number;
	includeSeconds?: boolean;
	addSuffix?: boolean;
}

export const UpdatedAgo: React.FC<UpdatedAgoProps> = ({
	from,
	refreshRate,
	includeSeconds,
	addSuffix
}) => {
	const [to, setTo] = useState(new Date());

	useEffect(() => setTo(new Date()), [from]);
	useEffect(() => {
		const timer = setTimeout(() => setTo(new Date()), refreshRate);
		return () => clearTimeout(timer);
	});

	return (
		<TitleExtra type='regular'>
			{formatDistance(from, to, { includeSeconds, addSuffix })}
		</TitleExtra>
	);
};

UpdatedAgo.defaultProps = {
	refreshRate: 10 * 1000,
	includeSeconds: true,
	addSuffix: true
};
