import React, { useState } from 'react';
import {
	createNavigatorFactory,
	NavigationHelpersContext,
	StackRouter,
	useNavigationBuilder
} from '@react-navigation/native';
import * as Styled from './styled';
import { NavBar } from '../../ui/layout/nav-bar/nav-bar';
import { EXTRA_ROUTES, NAV_ROUTES } from '../routes';
import { useTheme } from 'styled-components/native';

export const NavBarNavigator: React.FC = ({ children }) => {
	const [expanded, setExpanded] = useState(false);
	const { state, navigation, descriptors } = useNavigationBuilder(StackRouter, {
		children,
		initialRouteName: 'Vitals'
	});
	const activeRoute = state.routes.slice(state.routes.length - 1)[0];
	const theme = useTheme();

	const activeScreen = state?.routes
		?.map((route) => descriptors[route.key])
		.find((_, index) => state.index === index);

	return (
		<NavigationHelpersContext.Provider value={navigation}>
			<Styled.Container style={{ flexDirection: 'row' }}>
				<NavBar
					nav={{
						links: NAV_ROUTES.map((route) => {
							const active = route.name === activeRoute.name;
							const color = active
								? theme.components.navBar.link.active.foreground
								: theme.components.navBar.link.normal.foreground;
							return {
								key: route.name,
								title: route.title,
								name: route.name,
								active,
								icon: () => route.icon({ size: 36, color })
							};
						}),
						onLinkPress: (link) => {
							/* istanbul ignore next */
							if ('name' in link) {
								navigation.push(link.name);
							}
						}
					}}
					extra={EXTRA_ROUTES}
					onTogglePress={() => {
						setExpanded(!expanded);
					}}
					expanded={expanded}
				/>
				<Styled.Content>{activeScreen?.render()}</Styled.Content>
			</Styled.Container>
		</NavigationHelpersContext.Provider>
	);
};

export const createNavBarNavigator = createNavigatorFactory(NavBarNavigator);
