import colors from '../colors/colors';
import { Theme } from '../../../types/theme';

const theme: Theme = {
	charts: {},
	colors: {
		primary: colors.cobalt['700'],
		secondary: colors.cyan['500']
	},
	spacing: {
		tiny: 4,
		extraSmall: 8,
		small: 16,
		medium: 24,
		large: 32,
		extraLarge: 64
	},
	font: {
		primary: 'Inter_400Regular',
		primaryMedium: 'Inter_500Medium',
		primarySemibold: 'Inter_600SemiBold',
		colors: {
			primary: colors.cobalt['700'],
			secondary: colors.cobalt['500']
		}
	},
	screens: {
		sm: 576,
		md: 768,
		lg: 1536
	},
	components: {
		buttons: {
			primary: {
				background: colors.cobalt['700'],
				color: colors.cyan['600'],
				underlayColor: colors.purple['500']
			},
			link: {
				background: colors.transparent,
				color: colors.cyan['500'],
				underlayColor: colors.cyan['500']
			}
		},
		navBar: {
			background: colors.cobalt['900'],
			linksList: {
				background: colors.cobalt['700']
			},
			link: {
				normal: {
					background: colors.cobalt['700'],
					foreground: '#fff'
				},
				active: {
					background: colors.cyan['600'],
					foreground: colors.cobalt['700']
				},
				hover: {
					background: colors.cobalt['600'],
					foreground: '#fff'
				}
			}
		},
		screenHeader: {
			background: colors.cobalt['800'],
			foreground: '#fff'
		},
		screen: {
			background: colors.cobalt['800']
		},
		profileDropdown: {
			background: colors.cobalt['500'],
			foreground: '#fff',
			iconBackground: colors.purple['600'],
			iconForeground: colors.cobalt['700'],
			dividerColor: colors.cobalt['700']
		},
		connectivityLineChart: {
			background: colors.cobalt['700'],
			dataSetColors: {
				good: '#01DBC5',
				fair: '#94F0E5',
				poor: '#D47DD4',
				noData: '#B13587'
			},
			tooltip: {
				background: colors.cobalt['500'],
				foreground: '#fff'
			},
			arrows: {
				background: colors.cobalt['600'],
				foreground: '#E1E1E1'
			}
		},
		segmentedControl: {
			defaultBackground: colors.cobalt['600'],
			defaultForeground: '#fff',
			activeBackground: colors.cyan['500'],
			activeForeground: colors.cobalt['700']
		},
		dataTable: {
			background: colors.cobalt['700'],
			headerBackground: colors.cobalt['600'],
			headerTextColor: colors.grey['200'],
			rowDividerColor: colors.cobalt['400'],
			rowTextColor: colors.grey['100'],
			rowTextColorInverted: colors.cobalt['700'],
			iconBackground: colors.cobalt['700'],
			onlineDot: colors.cyan['500']
		},
		deviceCounter: {
			cell: {
				title: {
					fontFamily: 'Inter_600SemiBold',
					fontSize: '12px',
					color: colors.grey['100'],
					colorInverted: colors.cobalt['700']
				},
				body: {
					fontFamily: 'Inter_400Regular',
					fontSize: '20px',
					color: colors.grey['100'],
					colorInverted: colors.cobalt['700']
				},
				noData: {
					backgroundColor: colors.fuchsia['200']
				},
				poor: {
					backgroundColor: colors.fuchsia['100']
				},
				fair: {
					backgroundColor: colors.teal['100']
				},
				good: {
					backgroundColor: colors.teal['200']
				},
				total: {
					backgroundColor: colors.cobalt['600']
				}
			}
		},
		tag: {
			background: colors.cobalt['400'],
			foreground: colors.white
		},
		selectableTag: {
			selected: {
				background: colors.purple['500'],
				foreground: colors.cobalt['700']
			},
			unselected: {
				background: colors.cobalt['400'],
				foreground: colors.white
			}
		},
		checkbox: {
			checked: {
				background: colors.purple['500'],
				border: colors.purple['500']
			},
			unchecked: {
				background: colors.transparent,
				border: colors.white
			}
		},
		checkboxGroup: {
			selectedLabelColor: colors.grey['300']
		},
		modal: {
			overlay: 'rgba(3, 15, 28, 0.7)',
			background: colors.cobalt['600'],
			footer: {
				border: colors.cobalt['400']
			}
		}
	}
};

export default theme;
