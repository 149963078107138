import { useMediaQuery } from 'react-responsive';

export interface MediaQueries {
	isMobile: boolean;
	isTablet: boolean;
	isDesktop: boolean;
}

export const useMediaQueries = (): MediaQueries => {
	const isDesktop = useMediaQuery({ query: '(min-width: 1280px)' });
	const isTablet = useMediaQuery({
		query: '(min-width: 916px) and (max-width: 1279px)'
	});
	const isMobile = useMediaQuery({ query: '(max-width: 915px)' });

	return {
		isMobile,
		isTablet,
		isDesktop
	};
};
