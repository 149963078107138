import styled, { css } from 'styled-components/native';
import { DeviceConnectivityLevel } from './types';


// TODO (mirande): colors should be part of the theme
export const Container = styled.View`
	background-color: #08223d;
	border-radius: 12px;
	overflow: hidden;
`;

interface BoxProps {
	slim?: boolean;
}

export const Box = styled.View<BoxProps>`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	padding: 10px 10px 10px 8px

	${props => {
		if (props.slim){
			return css`
				flex-wrap: wrap;
			`;
		}
	}}
`;

interface CellProps {
	first?: boolean;
	last?: boolean;
	slim?: boolean;
	minWidth?: string;
}

const cellStyles = css<CellProps>`
	flex-grow: 0;
	flex-shrink: 1;
	flex-basis: auto;
	width: 100%;
	padding: 6px 8px;

	${props => {
		if (props.slim){
			return css`
				min-width: auto;
			`;
		}
	}}
`;

interface TitleCellProps extends CellProps {
	onClick?: () => void;
}

export const TitleCell = styled.View<TitleCellProps>`
	min-width: 390px;
	${cellStyles}
	background-color: transparent;
`;

export const TotalCell = styled.View<CellProps>`
	min-width: 160px;
	${cellStyles}
	border-top-left-radius: 10px;
	border-top-right-radius: 0;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 0;

	${props => {
		const total = props.theme.components.deviceCounter.cell.total;

		return css`
			backgroundColor: ${total.backgroundColor};
		`;
	}}

	${props => {
		if (props.slim){
			return css`
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-top: 10px;
				margin-bottom: 10px;
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
			`;
		}
	}}
`;

interface LevelCellProps extends CellProps {
	name: DeviceConnectivityLevel;
}

export const LevelCell = styled.View<LevelCellProps>`
	${cellStyles}

	${props => {
		const cell = props.theme.components.deviceCounter.cell[props.name];

		return css`
			backgroundColor: ${cell.backgroundColor};
		`;
	}}

	${props => {
		if (props.slim){
			if (props.first){
				return css`
					width: 25%;
					border-top-left-radius: 10px;
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
					border-bottom-left-radius: 10px;
				`;
			}

			if (props.last){
				return css`
					width: 25%;
					border-top-left-radius: 0;
					border-top-right-radius: 10px;
					border-bottom-right-radius: 10px;
					border-bottom-left-radius: 0;
				`;
			}

			return css`
				width: 25%;
			`;
		} else {
			if (props.last){
				return css`
					border-top-left-radius: 0;
					border-top-right-radius: 10px;
					border-bottom-right-radius: 10px;
					border-bottom-left-radius: 0;
				`;
			}
		}
	}}
`;

interface CellTextProps {
	slim?: boolean;
	inverted?: boolean;
}

export const CellTitleText = styled.Text<CellTextProps>`
	lineHeight: 18px;
	letter-spacing: 0.2px;

	${props => {
		const title = props.theme.components.deviceCounter.cell.title;
		const inverted = props.inverted;

		return css`
			fontSize: ${title.fontSize};
			fontFamily: ${title.fontFamily};
			color: ${inverted ? title.colorInverted : title.color};
		`;
	}}

	${props => {
		if (props.slim){
			return css`
				width: 50%;
			`;
		}
	}}
`;

export const CellBodyText = styled.Text<CellTextProps>`
	lineHeight: 27px;
	letter-spacing: 0;

	${props => {
		const body = props.theme.components.deviceCounter.cell.body;
		const inverted = props.inverted;

		return css`
			fontSize: ${body.fontSize};
			fontFamily: ${body.fontFamily};
			color: ${inverted ? body.colorInverted : body.color};
		`;
	}}

	${props => {
		if (props.slim){
			return css`
				width: 50%;
				text-align: right;
			`;
		}
	}}
`;

