const BASE_COLORS = {
	transparent: 'transparent',
	white: '#fff',
	cyan: {
		500: '#6AE9FC',
		600: '#00D6F3'
	},
	purple: {
		500: '#907DEC',
		600: '#765FE8'
	},
	grey: {
		100: '#F5F6FA',
		200: '#E2E4EB',
		300: '#BBBDC4'
	},
	cobalt: {
		400: '#516C90',
		500: '#284767',
		600: '#1B3856',
		700: '#08223D',
		800: '#04172A',
		900: '#030F1C'
	},
	fuchsia: {
		100: '#D47DD4',
		200: '#B13587'
	},
	teal: {
		100: '#94F0E5',
		200: '#01DBC5'
	}
};

export default BASE_COLORS;
