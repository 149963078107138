import { Platform } from 'react-native';
import { FlattenSimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components/native';
import { ContainerProps } from './types';

export const getWebOnlyStyle = (
	platformOS: string
): FlattenSimpleInterpolation => {
	if (platformOS === 'web') {
		return css({
			transition: '0.3s',
			backdropFilter: 'blur(6px)',
			pointerEvents: 'none'
		});
	}
	return css({});
};

export const Container = styled.View<ContainerProps>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(3, 15, 28, 0.7);
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	padding: 27px;
	z-index: 1;
	opacity: ${(props) => (props.visible ? 1 : 0)};
	${getWebOnlyStyle(Platform.OS)}
`;
