import styled from 'styled-components/native';
import { CheckProps } from './types';

export const Touchable = styled.TouchableWithoutFeedback``;

export const Container = styled.View`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const Check = styled.View<CheckProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 12px;
	height: 12px;
	border-width: 1px;
	border-radius: 2px;
	background-color: ${(props) =>
		props.checked
			? props.theme.components.checkbox.checked.background
			: props.theme.components.checkbox.unchecked.background};
	border-color: ${(props) =>
		props.checked
			? props.theme.components.checkbox.checked.border
			: props.theme.components.checkbox.unchecked.border};
`;

export const LabelWrapper = styled.View`
	margin-left: 6px;
`;
