import React from 'react';
import { Col } from 'react-native-easy-grid';
import { Label } from '../..';
import DownArrowIcon from '../../icons/down-arrow';
import UpArrowIcon from '../../icons/up-arrow';
import * as Styled from './styled';
import { HeaderProps } from './types';


export const Headers: React.FC<HeaderProps> = ({
	name,
	label,
	displaySortIcon,
	onPress,
	style,
	sortOrder
}) => {
	return (
		<Col style={style}>
			<Styled.Header
				onPress={() => {
					onPress(name);
				}}>
				<Label type='bold'>{label}</Label>
				{
					displaySortIcon
						? (
							<Styled.ArrowIconContainer>
								{
									sortOrder === 'asc'
										? <UpArrowIcon style={{ width: 14, margin: 'auto' }} />
										: <DownArrowIcon style={{ width: 14, margin: 'auto' }} />
								}
							</Styled.ArrowIconContainer>
						)
						: null
				}
			</Styled.Header>
		</Col>
	);
};
