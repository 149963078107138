import styled from 'styled-components/native';
import Type from '../type';
import { TitleProps } from './types';

export const Title = styled(Type)<TitleProps>`
	font-size: ${({ size }) => {
		switch (size) {
			case 'large':
				return '24px';
			case 'medium':
				return '20px';
			case 'small':
				return '16px';
		}
	}};
	font-family: ${({ size }) => {
		switch (size) {
			case 'large':
			case 'medium':
				return 'Inter_400Regular';
			case 'small':
				return 'Inter_600SemiBold';
		}
	}};
`;
