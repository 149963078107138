import React from 'react';
import { useTheme } from 'styled-components/native';
import { G, Path, Rect } from 'react-native-svg';
import { useArrowButtonState } from './use-arrow-button-state';

const LEFT_PATH =
	'M15.7364 5.51743C16.0879 5.17029 16.0879 4.60748 15.7364 4.26035C15.3849 3.91321 14.8151 3.91321 14.4636 4.26035L7.2636 11.3715C6.91213 11.7186 6.91213 12.2814 7.2636 12.6285L14.4636 19.7396C14.8151 20.0868 15.3849 20.0868 15.7364 19.7396C16.0879 19.3925 16.0879 18.8297 15.7364 18.4826L9.17287 12.0001L15.7364 5.51743Z';
const RIGHT_PATH =
	'M9.2636 18.4826C8.91213 18.8297 8.91213 19.3925 9.2636 19.7397C9.61508 20.0868 10.1849 20.0868 10.5364 19.7397L17.7364 12.6285C18.0879 12.2814 18.0879 11.7186 17.7364 11.3715L10.5364 4.26035C10.1849 3.91322 9.61508 3.91322 9.2636 4.26035C8.91213 4.60748 8.91213 5.1703 9.2636 5.51743L15.8271 11.9999L9.2636 18.4826Z';

interface ArrowButtonProps {
	x: number;
	y: number;
	direction: 'left' | 'right';
	disabled?: boolean;
	onPress: () => void;
	testId?: string;
}

export const ArrowButton: React.FC<ArrowButtonProps> = ({
	x,
	y,
	direction,
	disabled,
	onPress,
	testId
}) => {
	const theme = useTheme();
	const d = direction === 'left' ? LEFT_PATH : RIGHT_PATH;
	const { handlePress, isMobile, hover, ref } = useArrowButtonState({
		disabled,
		onPress
	});

	return (
		<>
			<G
				ref={ref}
				data-testid={testId}
				transform={`translate(${x}, ${y})`}
				opacity={disabled ? 0.3 : 1}
				onPress={handlePress}
			>
				<Rect
					x='0'
					y='0'
					width='24'
					height='24'
					rx='8'
					fill={
						isMobile || hover
							? theme.components.connectivityLineChart.arrows.background
							: 'transparent'
					}
				/>
				<Path
					fillRule='evenodd'
					clipRule='evenodd'
					d={d}
					fill={theme.components.connectivityLineChart.arrows.foreground}
				/>
			</G>
		</>
	);
};
