import { useEffect, useRef, useState } from 'react';
import { Platform } from 'react-native';
import { findDOMNode } from 'react-dom';

export const useArrowButtonState = ({
	disabled,
	onPress
}: {
	onPress: () => void;
	disabled?: boolean;
}) => {
	const ref = useRef(null);
	const [hover, setHover] = useState(false);
	const isMobile = Platform.OS !== 'web';

	const handlePress = () => {
		if (!disabled) {
			onPress();
		}
	};

	const handleMouseEnter = () => {
		if (!disabled) {
			setHover(true);
		}
	};
	const handleMouseLeave = () => {
		setHover(false);
	};

	useEffect(() => {
		if (isMobile || !ref?.current) {
			return;
		}
		const domNode = findDOMNode(ref.current);
		/* istanbul ignore next */
		if (!domNode || !(domNode instanceof Element)) {
			return;
		}

		domNode.setAttribute('cursor', disabled ? 'default' : 'pointer');
		domNode.addEventListener('click', handlePress);
		domNode.addEventListener('mouseenter', handleMouseEnter);
		domNode.addEventListener('mouseleave', handleMouseLeave);

		return () => {
			domNode.removeEventListener('click', handlePress);
			domNode.removeEventListener('mouseenter', handleMouseEnter);
			domNode.removeEventListener('mouseleave', handleMouseLeave);
		};
	}, [
		ref,
		disabled,
		isMobile,
		setHover,
		handlePress,
		handleMouseEnter,
		handleMouseLeave
	]);

	return {
		isMobile,
		hover,
		handlePress,
		handleMouseEnter,
		handleMouseLeave,
		ref
	};
};
