import styled from 'styled-components/native';
import { Label as BaseLabel } from '../type/label/styled';
import { ContainerProps, LabelProps } from './types';

export const Container = styled.Text`
	display: flex;
`;

export const Touchable = styled.TouchableOpacity<ContainerProps>`
	flex-direction: row;
	align-items: center;
	border-radius: 24px;
	background-color: ${(props) => props.background};
	padding-top: 2px;
	padding-bottom: 2px;
	padding-left: 8px;
	padding-right: 8px;
`;

export const Leading = styled.View`
	margin-right: 6px;
	align-items: center;
`;

export const Label = styled(BaseLabel)<LabelProps>`
	color: ${(props) => props.foreground};
`;
