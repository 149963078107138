import styled from 'styled-components/native';
import { TextInput } from 'react-native';

export const SearchInputContainer = styled.View`
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: #1b3856;
	width: 100%;
	border-radius: 8px;
`;

export const SearchInput = styled(TextInput)`
	flex: 1;
	padding-top: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	background-color: #1b3856;
	color: #f5f6fa;
	border-radius: 8px;
`;
