import { Platform } from 'react-native';
import * as SentrySDK from 'sentry-expo';
import Constants from 'expo-constants';
import { UserAuthType } from '../types/user-auth';

const settings = {
	enabled: Constants.manifest.extra?.sentry.enabled,
	dsn: Constants.manifest.extra?.sentry.dsn,
	environment: Constants.manifest.extra?.environment,
	debug: Constants.manifest.extra?.sentry.debug,
	shutdownTimeout: Constants.manifest.extra?.sentry.shutdownTimeout,
	release: Constants.manifest.extra?.version,
	tracesSampleRate: Constants.manifest.extra?.sentry.sampleRate,
	enableInExpoDevelopment: Constants.manifest.extra?.sentry.enableInExpoDevelopment,
};

const Sentry = {
	init(): void {
		if (Platform.OS === 'web') {
			SentrySDK.Browser.init(settings);
		} else {
			SentrySDK.init(settings);
		}
	},
	setUser(user: UserAuthType): void {
		if (Platform.OS === 'web') {
			SentrySDK.Browser.setUser({ username: user.sub });
		} else {
			SentrySDK.Native.setUser({ username: user.sub });
		}
	}
};


export { Sentry, SentrySDK };
