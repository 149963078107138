import styled from 'styled-components/native';
import LabelType from '../type/label/label';
import { ItemProps } from './types';

export const Container = styled.View`
	flex-direction: row;
	background-color: ${(props) =>
		props.theme.components.segmentedControl.defaultBackground};
	border-radius: 8px;
	overflow: hidden;
`;

export const Item = styled.TouchableOpacity<ItemProps>`
	flex: 1;
	align-items: center;
	padding-top: 9px;
	padding-bottom: 9px;
	padding-left: 8px;
	padding-right: 8px;
	border-right-width: ${(props) => (props.last ? '0px' : '1px')};
	border-color: ${(props) =>
		props.theme.components.segmentedControl.activeForeground};
	background-color: ${(props) =>
		props.active
			? props.theme.components.segmentedControl.activeBackground
			: 'transparent'};
`;

export const Label = styled(LabelType)<ItemProps>`
	color: ${(props) =>
		props.active
			? props.theme.components.segmentedControl.activeForeground
			: props.theme.components.segmentedControl.defaultForeground};
`;
