import { CHART_WIDTH_TO_TICKS_RATIO } from './constants';

export const formatYTick = (tick: number): string => {
	let current = tick;
	const chunks = [];
	while (current > 1000) {
		const rest = current % 1000;
		chunks.unshift(rest);
		current = Math.floor(current / 1000);
	}
	const rest = current % 1000;
	chunks.unshift(rest);

	return chunks.join(',');
};

export const getTicksNumberForChartWidth = (chartWidth?: number): number =>
	chartWidth ? Math.floor(chartWidth / CHART_WIDTH_TO_TICKS_RATIO) : 0;
