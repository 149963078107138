import styled from 'styled-components/native';
import { Label } from '../type';

export const Container = styled.View``;

export const Title = styled.Text`
	margin-bottom: 3px;
`;

export const OptionWrapper = styled.View`
	padding-top: 5px;
	padding-bottom: 5px;
`;

export const SelectedLabel = styled(Label)`
	color: ${(props) => props.theme.components.checkboxGroup.selectedLabelColor};
`;
