import * as SegmentSDK from 'expo-analytics-segment';
import { Platform } from 'react-native';
import Constants from 'expo-constants';
import { UserAuthType } from '../types/user-auth';

declare global {
	interface Window {
		analytics: {
			track: (event: string, properties: Record<string, unknown>) => void
			page: () => void
			identify: (userId: string, traits: Record<string, unknown>) => void
		};
	}
}

const Segment = {
	init(): void {
		if (Constants.manifest.extra?.segment.enabled && Platform.OS !== 'web') {
			SegmentSDK.initialize({
				iosWriteKey: Constants.manifest.extra?.segment.ios.key,
				androidWriteKey: Constants.manifest.extra?.segment.android.key,
			});
		}
	},
	track(event: string, properties: Record<string, unknown>): void {
		if (Constants.manifest.extra?.segment.enabled) {
			if (Platform.OS === 'web') {
				window.analytics.track(event, properties);
			} else {
				SegmentSDK.trackWithProperties(event, properties);
			}
		}
	},
	screenOrPage(screenName: string): void {
		if (Constants.manifest.extra?.segment.enabled) {
			if (Platform.OS === 'web') {
				window.analytics.page();
			} else {
				SegmentSDK.screen(screenName);
			}
		}
	},
	identify(user: UserAuthType): void {
		if (Platform.OS === 'web') {
			window.analytics.identify(user.particle_user_id, { email: user.sub });
		} else {
			SegmentSDK.identifyWithTraits(user.particle_user_id, { email: user.sub });
		}
	}
};

export { Segment };
