import React, { useState, useEffect } from 'react';
import Constants from 'expo-constants';
import { Platform } from 'react-native';
import * as AuthSession from 'expo-auth-session';
import { maybeCompleteAuthSession } from 'expo-web-browser';
import { useAuthRequest, useAutoDiscovery } from 'expo-auth-session';

import { UserAuthType, TokenDelegatesType, UserDelegatesType, ClientBrandType } from '../types/user-auth';
import { requestOktaAuth, verifySession, revokeSession, fetchBranding } from '../lib/okta-auth';
import AuthCheck from '../components/navigation/auth-check';
import { getClient } from '../lib/graphql-client';

export interface AuthContextType {
	token?: string,
	user?: UserAuthType,
	tokenDelegates?: TokenDelegatesType,
	userDelegates?: UserDelegatesType,
	error: boolean,
	loading: boolean,
	requestAuth: (() => Promise<void>);
	setError?: ((error: boolean) => Promise<void>),
	logout: (() => Promise<void>);
}

const noop = async () => { /* do nothing */ };
const initialAuthContext = {
	token: undefined,
	user: undefined,
	error: false,
	loading: false,
	requestAuth: noop,
	logout: noop
};

export const AuthContext = React.createContext<AuthContextType>(initialAuthContext);
export const useProxy = false;

interface AuthProviderProps {
	children: React.ReactNode;
	onUserSet?: (user: UserAuthType) => void;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children, onUserSet }) => {
	if (Platform.OS === 'web') {
		maybeCompleteAuthSession();
	}

	const [token, setTokenState] = useState<string|undefined>(undefined);
	const [error, setErrorState] = useState<boolean>(false);
	const [user, setUserState] = useState<UserAuthType|undefined>(undefined);
	const [authFinished, setAuthFinished] = useState<boolean>(false);
	const discovery = useAutoDiscovery(
		Constants.manifest.extra?.oktaAuth?.discovery
	);
	const [branding, setBrandingState] = useState<ClientBrandType|undefined>(undefined);

	useEffect(() => {
		verifySession({ tokenDelegates, userDelegates, discovery })
			.then(() => setAuthFinished(true));
	}, []);

	// Request
	const redirectUri = AuthSession.makeRedirectUri({
		native: `${Constants.manifest.scheme}://auth`,
		path: '/',
		preferLocalhost: Constants.manifest.extra?.oktaAuth?.runLocal
	});

	const [request, response, promptAsync] = useAuthRequest( // eslint-disable-line @typescript-eslint/no-unused-vars
		{
			clientId: Constants.manifest.extra?.oktaAuth?.clientId,
			scopes: ['openid', 'profile', 'offline_access'],
			responseType: AuthSession.ResponseType.Code,
			usePKCE: true,
			extraParams: {
				nonce: 'nonce',
			},
			redirectUri,
		},
		discovery
	);

	const tokenDelegates = {
		setToken: async (token: string) => {
			setTokenState(token);
		},

		clearToken: async () => {
			setTokenState(undefined);
		}
	};

	const userDelegates = {
		setUser: async (user: UserAuthType) => {
			if (typeof onUserSet === 'function') {
				onUserSet(user);
			}
			setUserState(user);
		},

		clearUser: async () => {
			setUserState(undefined);
		}
	};

	const setError = async (error: boolean) => {
		setErrorState(error);
	};

	const logout = async() => {
		const client = await getClient();
		await tokenDelegates.clearToken();
		await userDelegates.clearUser();
		await revokeSession(discovery);
		await client.clearStore();
	};

	const requestAuth = requestOktaAuth({
		promptAsync, useProxy, setError, tokenDelegates, userDelegates, discovery, request, redirectUri
	});

	useEffect(() => {
		fetchBranding().then(
			res => setBrandingState(res)
		);
	}, []);

	return (
		<AuthContext.Provider value={{
			token,
			tokenDelegates,
			userDelegates,
			loading: !authFinished,
			requestAuth: requestAuth.requestAuthResolve,
			error,
			user,
			setError,
			logout }}>
			<AuthCheck branding={branding}>
				{ children }
			</AuthCheck>
		</AuthContext.Provider>
	);
};


export default AuthProvider;
