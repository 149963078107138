import React from 'react';
import { IconButtonProps } from './types';
import * as Icons from '../../icons';
import * as Styled from './styled';

export const IconButton: React.FC<IconButtonProps> = ({
	iconName,
	onPress,
	...iconProps
}) => {
	const Icn = Icons[iconName];
	return (
		<Styled.Container onPress={onPress}>
			<Icn {...iconProps} />
		</Styled.Container>
	);
};
