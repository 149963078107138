import React from 'react';
import { TagsGroupProps } from './types';
import * as Styled from './styled';
import { Label } from '../type';
import { SelectableTag } from '../selectable-tag';
import { Spacer } from '../spacer';
import { useMultiSelectState } from '../../../hooks';

export const TagsGroup: React.FC<TagsGroupProps> = ({
	title,
	tags,
	initiallySelected,
	value,
	onSelect
}) => {
	const { isSelected, toggle } = useMultiSelectState<string>({
		initialState: initiallySelected,
		value,
		onSelect
	});

	return (
		<Styled.Container>
			<Label type='bold'>{title}</Label>
			<Spacer direction='vertical' size='extraSmall' />
			<Styled.Tags>
				{tags.map((tag, index) => (
					<Styled.TagWrapper key={tag.value} last={index === tags.length - 1}>
						<SelectableTag
							label={tag.label}
							selected={isSelected(tag.value)}
							onPress={() => toggle(tag.value)}
						/>
					</Styled.TagWrapper>
				))}
			</Styled.Tags>
		</Styled.Container>
	);
};
