import React from 'react';
import { useTheme } from 'styled-components/native';
import formatDate from 'date-fns/format';
import { VictoryTooltipProps } from 'victory';
import { ConnectivityInfo } from './types';
import { getFlyoutSafeAreaX, getFlyoutSafeAreaY } from './utils';
import { FLYOUT_HEIGHT, FLYOUT_WIDTH } from './constants';

interface ConnectivityLevelFlyoutProps extends VictoryTooltipProps {
	datum?: ConnectivityInfo;
	chartSize: {
		width: number;
		height: number;
	};
}

interface TimestampLegendProps {
	y: number;
	name: string;
	value: number;
	color: string;
}

const TimestampLegend: React.FC<TimestampLegendProps> = (props) => {
	const theme = useTheme();
	const { y, name, value, color } = props;
	return (
		<g transform={`translate(0, ${y})`}>
			<circle cx={5.6} cy={5.6} r={5.6} fill={color} />
			<text
				x={22.4}
				y={11.2}
				fill={theme.components.connectivityLineChart.tooltip.foreground}
				style={{
					fontSize: 14,
					fontFamily: 'Inter_400Regular'
				}}
			>
				{name}
			</text>
			<text
				textAnchor='end'
				x={190.4}
				y={11.2}
				fill={theme.components.connectivityLineChart.tooltip.foreground}
				style={{
					fontSize: 14,
					fontFamily: 'Inter_400Regular'
				}}
			>
				{value} devices
			</text>
		</g>
	);
};

export const ConnectivityLevelFlyout: React.FC<ConnectivityLevelFlyoutProps> = (
	props
) => {
	const theme = useTheme();
	const x = getFlyoutSafeAreaX(props.x || 0, props.chartSize.width);
	const y = getFlyoutSafeAreaY(props.y || 0, props.chartSize.height);

	if (!props.datum) {
		return null;
	}

	return (
		<g transform={`translate(${x}, ${y})`}>
			<rect
				width={FLYOUT_WIDTH}
				height={FLYOUT_HEIGHT}
				rx={11.2}
				fill={theme.components.connectivityLineChart.tooltip.background}
			/>
			<g transform='translate(11.2, 11.2)'>
				<text
					x={0}
					y={14}
					fill={theme.components.connectivityLineChart.tooltip.foreground}
					style={{
						fontSize: 14,
						fontFamily: 'Inter_600SemiBold'
					}}
				>
					{formatDate(
						new Date(props.datum.timeStamp).getTime(),
						'MMM d, HH:mm'
					)}
				</text>
				<g transform='translate(0, 30.8)'>
					<TimestampLegend
						y={0}
						name='No Data'
						color={theme.components.connectivityLineChart.dataSetColors.noData}
						value={Math.round(props.datum.noData)}
					/>
					<TimestampLegend
						y={28}
						name='Poor'
						color={theme.components.connectivityLineChart.dataSetColors.poor}
						value={Math.round(props.datum.poor)}
					/>
					<TimestampLegend
						y={56}
						name='Fair'
						color={theme.components.connectivityLineChart.dataSetColors.fair}
						value={Math.round(props.datum.fair)}
					/>
					<TimestampLegend
						y={84}
						name='Good'
						color={theme.components.connectivityLineChart.dataSetColors.good}
						value={Math.round(props.datum.good)}
					/>
				</g>
			</g>
		</g>
	);
};
