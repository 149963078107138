import React from 'react';
import { useTheme } from 'styled-components/native';
import { Defs, G, LinearGradient, Mask, Rect, Stop } from 'react-native-svg';

interface FadeAxisProps {
	width: number;
	height: number;
}
export const FadeAxis: React.FC<FadeAxisProps> = ({ width, height }) => {
	const theme = useTheme();

	return (
		<G transform={`translate(0, ${height - 45})`}>
			<Defs>
				<LinearGradient id='left-gradient'>
					<Stop offset='0%' stopColor='white' />
					<Stop offset='60%' stopColor='white' />
					<Stop offset='100%' stopColor='black' />
				</LinearGradient>
				<LinearGradient id='right-gradient'>
					<Stop offset='0%' stopColor='black' />
					<Stop offset='30%' stopColor='white' />
					<Stop offset='100%' stopColor='white' />
				</LinearGradient>
				<Mask id='left-mask'>
					<Rect height='40' width='90' fill='url(#left-gradient)' />
				</Mask>
				<Mask id='right-mask'>
					<Rect
						x={width - 50}
						height='40'
						width='50'
						fill='url(#right-gradient)'
					/>
				</Mask>
			</Defs>
			<Rect
				height='40'
				width='90'
				fill={theme.components.connectivityLineChart.background}
				mask='url(#left-mask)'
			/>
			<Rect
				x={width - 50}
				height='40'
				width='50'
				fill={theme.components.connectivityLineChart.background}
				mask='url(#right-mask)'
			/>
		</G>
	);
};
