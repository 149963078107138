import React from 'react';
import * as Styled from './styled';
import { Picker } from '@react-native-picker/picker';
import { ArrowNextIcon, ArrowPreviousIcon } from '..';
import { PaginatorProps } from './types';


export const Paginator: React.FC<PaginatorProps> = ({
	count,
	index,
	pageSizes,
	pageSize,
	onNext,
	onPrevious,
	onPageSizeChanged
}) => {
	const canPagePrevious = index > 0;
	const canPageNext = index < count - pageSize;

	return (
		<Styled.Paginator>
			<Styled.PaginatorText size='small'>View</Styled.PaginatorText>
			<Styled.PaginatorPicker
				selectedValue={pageSize}
				onValueChange={onPageSizeChanged}>
				{
					pageSizes.map((el, i) => {
						return <Picker.Item key={i} label={el} value={el} />;
					})
				}
			</Styled.PaginatorPicker>
			<Styled.PaginatorText size='small'>{index + 1} - {(index + pageSize) > count ? count : index + pageSize} of {count}</Styled.PaginatorText>
			<Styled.PaginatorArrow disabled={!canPagePrevious} onPress={onPrevious}>
				<ArrowPreviousIcon style={{ width: 24, height: 24 }} viewBox='0 0 24 24' />
			</Styled.PaginatorArrow>
			<Styled.PaginatorArrow disabled={!canPageNext} onPress={onNext}>
				<ArrowNextIcon style={{ width: 24, height: 24 }} viewBox='0 0 24 24' />
			</Styled.PaginatorArrow>
		</Styled.Paginator>
	);
};
