import styled from 'styled-components/native';
import Type from '../type';
import { ParagraphProps } from './types';

export const Paragaraph = styled(Type)<ParagraphProps>`
	font-size: ${({ size }) => {
		switch (size) {
			case 'regular':
				return '16px';
			case 'small':
				return '12px';
		}
	}};
	font-family: Inter_400Regular;
`;
