import styled from 'styled-components/native';
import Label from '../../type/label/label';

export const Container = styled.View`
	background-color: #08223d;
	border-radius: 12px;
	overflow: hidden;
`;

export const Body = styled.View`
	padding: 17px;
`;

export const TimeWindowSegmentedControlContainer = styled.View`
	align-self: flex-start;
	width: 240px;
`;

export const TitleBar = styled.View`
	padding: 17px;
	flex-direction: row;
	align-items: flex-end;
	border-bottom-width: 1px;
	border-color: #284767;
`;

export const TitleExtra = styled(Label)`
	color: #bbbdc4;
`;

export const ChartContainer = styled.View`
	position: relative;
	margin-top: 16px;
	border-radius: 12px;
	overflow: hidden;
`;
