import { useState } from 'react';

export const useMultiSelectState = <T extends unknown>({
	initialState,
	value,
	onSelect
}: {
	initialState?: Set<T>;
	value?: Set<T>;
	onSelect: (selected: Set<T>) => void;
}) => {
	const isControlled = value !== undefined;
	const [selected, setSelected] = useState<Set<T>>(new Set(initialState));
	const handleSelectionChanged = (newSelected: Set<T>) => {
		if (!isControlled) {
			setSelected(newSelected);
		}
		onSelect(newSelected);
	};

	const getSelected = () => value || selected;
	const isSelected = (value: T) => getSelected().has(value);
	const select = (value: T) => {
		const newState = new Set(getSelected());
		newState.add(value);
		handleSelectionChanged(newState);
	};
	const unselect = (value: T) => {
		const newState = new Set(getSelected());
		newState.delete(value);
		handleSelectionChanged(newState);
	};
	const toggle = (value: T) => {
		if (isSelected(value)) {
			unselect(value);
		} else {
			select(value);
		}
	};
	const clear = () => {
		handleSelectionChanged(new Set());
	};

	return {
		isSelected,
		select,
		unselect,
		toggle,
		clear,
		count: getSelected().size
	};
};
