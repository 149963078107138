import React from 'react';
import { useTheme } from 'styled-components/native';
import * as Styled from './styled';
import { TagProps } from './types';

export const Tag: React.FC<TagProps> = ({
	label,
	leading,
	onPress,
	background,
	foreground,
	weight = 'regular'
}) => {
	const theme = useTheme();
	const bg = background || theme.components.tag.background;
	const fg = foreground || theme.components.tag.foreground;

	return (
		<Styled.Container>
			<Styled.Touchable background={bg} disabled={!onPress} onPress={onPress}>
				{leading && <Styled.Leading>{leading}</Styled.Leading>}
				<Styled.Label type={weight} foreground={fg}>
					{label}
				</Styled.Label>
			</Styled.Touchable>
		</Styled.Container>
	);
};
