import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH =
	'M12,21.56L6.44,16L4.547,17.88L12,25.333L28,9.333L26.12,7.453L12,21.56Z';

const CheckmarkIcon: React.FC<IconProps> = ({ size, color }) => {
	return <Icon path={ICON_PATH} size={size} color={color} />;
};

export default CheckmarkIcon;
