import React from 'react';
import { View } from 'react-native';
import { useAuth, useProfile } from '../../../../../hooks';
import { LogoutIcon } from '../../../icons';
import Paragraph from '../../../type/paragraph/paragraph';
import { ProfileIconWithBackground } from './profile-icon-with-background';
import * as Styled from './styled';
import { TEST_IDS } from './constants';

interface ContentProps {
	setOpen: (open: boolean) => void;
}

export const Content: React.FC<ContentProps> = ({ setOpen }) => {
	const { username, firstName, lastName } = useProfile();
	const fullName = `${firstName} ${lastName}`;
	const { logout } = useAuth();

	const handleLogout = async () => {
		setOpen(false);
		await logout();
	};

	const handleUserPress = async () => {
		setOpen(false);
	};

	return (
		<Styled.Content>
			<Styled.Item onPress={handleUserPress}>
				<Styled.ItemLeading>
					<ProfileIconWithBackground />
				</Styled.ItemLeading>
				<View testID={TEST_IDS.content}>
					<Paragraph size='regular'>{fullName}</Paragraph>
					<Paragraph size='small'>{username}</Paragraph>
				</View>
			</Styled.Item>
			<Styled.Item onPress={handleLogout} last>
				<Styled.ItemLeading>
					<LogoutIcon size='24px' />
				</Styled.ItemLeading>
				<Paragraph size='regular'>Logout</Paragraph>
			</Styled.Item>
		</Styled.Content>
	);
};
