/* istanbul ignore file */
import React, { useEffect, useState, useRef } from 'react';
import '@expo/match-media';
import { Platform, View } from 'react-native';
import { registerRootComponent } from 'expo';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { NavigationContainer } from '@react-navigation/native';
import { NavigationContainerRef } from '@react-navigation/core';
import createGlobalStyles from './components/ui/global-styles';
import { getClient } from './lib/graphql-client';
import Theme from './components/ui/themes/theme';
import Title from './components/ui/type/title/title';
import { ParticleMarkIcon } from './components/ui/icons/particle-mark-icon';
import { MainNavigator } from './components/navigation/main-navigator';
import AuthProvider from './context/auth-context';
import { Sentry } from './lib/sentry';
import {
	useFonts,
	Inter_400Regular as inter400Regular,
	Inter_600SemiBold as inter600SemiBold
} from '@expo-google-fonts/inter';
import { Segment } from './lib/segment';
import { UserAuthType } from './types/user-auth';

Sentry.init();
Segment.init();
const GlobalStyles = createGlobalStyles(Platform.OS);

const linking = {
	prefixes: ['particle://'],
	config: {
		screens: {
			Home: '/',
			Vitals: 'vitals',
			Connectivity: 'connectivity'
		}
	}
};

const App: React.FC = () => {
	const [client, setClient] = useState<ApolloClient<any> | undefined>( // eslint-disable-line @typescript-eslint/no-explicit-any
		undefined
	);
	const navigationRef = useRef<NavigationContainerRef>(null);
	const routeNameRef = useRef<string | null>(null);

	useFonts({
		Inter_400Regular: inter400Regular,
		Inter_600SemiBold: inter600SemiBold
	});

	useEffect(() => {
		getClient().then((_client) => {
			setClient(_client);
		});
	}, []);

	if (!client) {
		return (
			<View>
				<ParticleMarkIcon size='250' />
				<Title size='medium'>Loading...</Title>
			</View>
		);
	}

	const handleRouteInit = () => {
		routeNameRef.current = navigationRef.current!.getCurrentRoute()!.name;
		Segment.screenOrPage(navigationRef.current!.getCurrentRoute()!.name);
	};

	const handleRouteChange = () => {
		const previousRouteName = routeNameRef.current;
		const currentRouteName = navigationRef.current!.getCurrentRoute()!.name;

		if (previousRouteName !== currentRouteName) {
			Segment.screenOrPage(currentRouteName);
		}

		routeNameRef.current = currentRouteName;
	};

	const setUser = (user: UserAuthType) => {
		Sentry.setUser(user);
		Segment.identify(user);
	};

	return (
		<ApolloProvider client={client}>
			<GlobalStyles />
			<Theme>
				<AuthProvider onUserSet={setUser}>
					{() => (
						<NavigationContainer
							ref={navigationRef}
							linking={linking}
							onReady={handleRouteInit}
							onStateChange={handleRouteChange}
						>
							<MainNavigator />
						</NavigationContainer>
					)}
				</AuthProvider>
			</Theme>
		</ApolloProvider>
	);
};

export default App;

// TODO (mirande): this prevent testing as it errors with:
// 'TypeError: Cannot read property 'match' of null'
// at getDevServer (node_modules/react-native/Libraries/Core/Devtools/getDevServer.js:30:19)
registerRootComponent(App);
